import { makeStyles } from "@material-ui/core/styles";

export const useCardPostStyles = makeStyles(
  ({ palette, spacing, typography }) => ({
    card: {
      border: 0,
      backgroundColor: "transparent !important",
      display: "flex",
      flexFlow: "column nowrap",
      padding: 0,
      textDecoration: "none !important",
    },
    caption: {
      textAlign: "center",
      margin: spacing(4, 0),
    },
    content: {
      padding: spacing(2, 0, 0, 0),
      marginBottom: 0,
      maxWidth: 650,
      margin: "auto",
    },
    context: {
      textAlign: "center",
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1.25,
      "&.company": {
        fontWeight: typography.fontWeightRegular,
        color: palette.text.secondary,
      },
      "&:hover": {
        color: palette.text.secondary,
      },
    },
    link: {
      color: palette.text.primary,
      "&:hover": {
        color: palette.primary.dark,
      },
    },
    meta: {
      justifyContent: "flex-start",
      marginBottom: 0,
      maxWidth: "none",
      display: "none",
    },
  })
);
