import { makeStyles } from "@material-ui/core/styles";

// TODO:
// - use custom gradient colors from palette for background image gradient overlays

export const useSectionStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    margin: "0 auto",
    position: "relative",
    "&.cta": {
      [breakpoints.up("md")]: {
        maxWidth: 800,
      },
    },
  },
  image: {
    margin: "0 auto",
    width: "100%",
    "&.background": {
      height: "100%",
      left: 0,
      position: "absolute !important",
      top: 0,
      zIndex: -1,
      "&::before": {
        background:
          "linear-gradient(90deg, rgba(80, 80, 98, 0.9) 0%, rgba(80, 80, 98, 0.9) 100%)",
        content: "''",
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
        zIndex: 1,
      },
      "&.primary, .primary &": {
        "&::before": {
          background:
            "linear-gradient(90deg, rgba(39, 56, 139, 0.9) 0%, rgba(39, 56, 139, 0.9) 100%)",
        },
      },
      "&.secondary, .secondary &": {
        "&::before": {
          background:
            "linear-gradient(90deg, rgba(15, 19, 35, 0.9) 0%, rgba(15, 19, 35, 0.9) 100%)",
        },
      },
      "&.clear": {
        "&::before": {
          content: "none",
        },
      },
    },
  },
  section: {
    padding: spacing(4, 0),
    position: "relative",
    [breakpoints.up("sm")]: {
      paddingBottom: spacing(6),
      paddingTop: spacing(6),
    },
    [breakpoints.up("md")]: {
      paddingBottom: spacing(8),
      paddingTop: spacing(8),
    },
    [breakpoints.up("lg")]: {
      paddingBottom: spacing(10),
      paddingTop: spacing(10),
    },
    "&.compact": {
      padding: "0 !important",
    },
    "&.fluid": {
      margin: "0 auto",
      width: "100%",
    },
    "&.hero": {
      padding: spacing(12, 0),
    },
  },
}));
