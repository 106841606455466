import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";

import { FormField } from "../default/FormField";
import { defaultProps, propTypes } from "../config";

import { DropzoneInput } from "./input/DropzoneInput";

export const FieldDropzone = ({
  dropzoneProps,
  fieldProps,
  multiple,
  ...rest
}) => {
  return (
    <FormField {...rest}>
      <Field name={rest.name} {...fieldProps}>
        {({ input }) => (
          <DropzoneInput
            disabled={rest.disabled}
            {...dropzoneProps}
            {...input}
            files={rest.files}
            setFiles={rest.setFiles}
            multiple={multiple}
          />
        )}
      </Field>
    </FormField>
  );
};

FieldDropzone.defaultProps = {
  ...defaultProps,
  dropzoneProps: undefined,
  fieldProps: undefined,
};

FieldDropzone.propTypes = {
  ...propTypes,
  dropzoneProps: PropTypes.any, // https://react-dropzone.js.org/
  fieldProps: PropTypes.any, // https://final-form.org/docs/react-final-form/types/FieldProps
};
