import { bool, elementType, node, number, oneOf } from "prop-types";

export const paperDefaultProps = {
  component: "div",
  elevation: 0,
  square: false,
  variant: "elevation",
};

export const paperPropTypes = {
  children: node.isRequired,
  component: elementType,
  elevation: number,
  square: bool,
  variant: oneOf(["elevation", "outlined"]),
  // ...restPaperProps: https://material-ui.com/api/paper/#props
};
