import { makeStyles } from "@material-ui/core/styles";

export const useListStyles = makeStyles(({ breakpoints, spacing }) => ({
  list: {
    listStyle: "disc",
    margin: spacing(0, 0, 2),
    padding: 0,
    paddingLeft: spacing(2.5),
    [breakpoints.up("md")]: {
      marginBottom: spacing(3),
    },
    "&.unstyled": {
      listStyle: "none",
    },
  },
}));
