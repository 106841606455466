import PropTypes from "prop-types";

import { sectionDefaultProps, sectionPropTypes } from "../default/config";

export const sectionResourcesDefaultProps = {
  ...sectionDefaultProps,
  heading: null,
  headingProps: {
    component: "h3",
    variant: "h2",
  },
  resources: null,
  resourcesProps: {
    component: "ul",
    spacing: 4,
  },
  resourceProps: {
    component: "li",
    md: 6,
    xs: 12,
  },
  link: null,
  linkProps: {
    color: "textPrimary",
    className: null,
    variant: "h4",
  },
  mascot: null,
};

export const resourceCategoryTypes = PropTypes.oneOf([
  "Code",
  "Collaboration",
  "Communication",
  "Communities",
  "Design",
  "Fonts",
  "Icons",
  "Inspiration",
  "Podcasts",
  "Project Management",
  "Reading",
  "Stock Photos",
  "Team Building",
  "Time Tracking",
  "Tutorials",
  "Website",
]);

export const sectionResourcesPropTypes = {
  ...sectionPropTypes,
  heading: PropTypes.string,
  headingProps: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
      className: PropTypes.string,
      color: PropTypes.oneOf([
        "initial",
        "inherit",
        "primary",
        "secondary",
        "textPrimary",
        "textSecondary",
        "error",
      ]), // color options are limited, use className for more styling options.
      component: PropTypes.elementType,
      variant: PropTypes.oneOf([
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "subtitle1",
        "subtitle2",
        "body1",
        "body2",
        "caption",
        "button",
        "overline",
        "srOnly",
        "inherit",
      ]), // configure typography variants in theme.
    }), // headingProps: https://material-ui.com/api/typography/#props
  ]),
  resourceProps: PropTypes.shape({
    xs: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  resources: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          data: PropTypes.shape({
            category: resourceCategoryTypes,
            date: PropTypes.string,
            description: PropTypes.string,
            id: PropTypes.string,
            image: PropTypes.oneOfType([
              PropTypes.shape({
                localFiles: PropTypes.arrayOf(
                  PropTypes.shape({
                    childImageSharp: PropTypes.shape({
                      fluid: PropTypes.shape({
                        base64: PropTypes.string,
                        aspectRatio: PropTypes.number,
                        src: PropTypes.string,
                        srcSet: PropTypes.string,
                        sizes: PropTypes.string,
                      }),
                    }),
                    name: PropTypes.string,
                  })
                ),
              }),
              PropTypes.string,
            ]),
            link: PropTypes.string,
            title: PropTypes.string,
          }),
          id: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  resourcesProps: PropTypes.shape({
    className: PropTypes.string,
    spacing: PropTypes.number,
  }),
  link: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  }),
  linkProps: PropTypes.shape({
    className: PropTypes.string,
    variant: PropTypes.oneOf([
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "srOnly",
      "inherit",
    ]),
  }),
  mascot: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    className: PropTypes.string,
    name: PropTypes.string,
  }),
};
