import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { kebabCase } from "lodash";

import { Link } from "components";
import { useAvatarsQuery } from "graphql";

import { useAuthorStyles } from "./Author.styles";

export const Author = ({ className, name }) => {
  const styles = useAuthorStyles();
  const { nodes } = useAvatarsQuery();

  if (!name) return null;

  const avatarName = `avatar-${name.replace(/ .*/, "").toLowerCase()}`;
  const avatar = nodes?.find(({ name }) => name === avatarName);

  return (
    <Link
      className={classNames(styles.author, className)}
      variant="caption"
      to={`/about/#${kebabCase(name)}`}
    >
      {avatar && <Img alt={avatarName} {...avatar.childImageSharp} />}
      {name}
    </Link>
  );
};
