import PropTypes from "prop-types";

import { sectionDefaultProps, sectionPropTypes } from "../default/config";

export const sectionSplitDefaultProps = {
  ...sectionDefaultProps,
  gridProps: {
    spacing: 4,
  },
  isFlipped: false,
  leftProps: {
    md: 4,
    xs: 12,
  },
  rightProps: {
    md: 8,
    xs: 12,
  },
};

export const sectionSplitPropTypes = {
  ...sectionPropTypes,
  gridProps: PropTypes.shape({
    className: PropTypes.string,
    spacing: PropTypes.number,
  }), // gridProps: https://material-ui.com/api/grid/#props
  isFlippped: PropTypes.bool,
  left: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  leftProps: PropTypes.shape({
    className: PropTypes.string,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xs: PropTypes.number,
  }), // leftProps: https://material-ui.com/api/grid/#props
  right: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  rightProps: PropTypes.shape({
    className: PropTypes.string,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xs: PropTypes.number,
  }), // rightProps: https://material-ui.com/api/grid/#props
};
