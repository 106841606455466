import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import {
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@material-ui/core";

import { Button, Link } from "components";

import { useCardStyles } from "./Card.styles";
import { cardDefaultProps, cardPropTypes } from "./config";

export const Card = ({
  actions,
  actionsProps,
  buttonProps,
  buttons,
  children,
  className,
  content,
  contentProps,
  context,
  contextProps,
  footer,
  header,
  headerProps,
  heading,
  headingProps,
  image,
  mediaProps,
  ...restCardProps
}) => {
  const styles = useCardStyles();
  const showContent = content || context || heading;

  return (
    <MuiCard {...restCardProps} className={classNames(styles.card, className)}>
      {header && (
        <CardHeader
          action={header.button}
          avatar={
            header.image && header.image.childImageSharp ? (
              <Img
                alt={header.image.name || header.heading}
                className={classNames(styles.image, header.image.className)}
                {...header.image.childImageSharp}
              />
            ) : (
              header.image
            )
          }
          subheader={header.context}
          title={header.heading}
          {...headerProps}
          className={classNames(styles.header, headerProps.className)}
        />
      )}
      {image &&
        (image.childImageSharp ? (
          <Img
            alt={image.name || heading}
            className={classNames(styles.image, image.className)}
            {...image.childImageSharp}
          />
        ) : (
          <CardMedia
            {...image}
            {...mediaProps}
            className={classNames(styles.media, mediaProps.className)}
          />
        ))}
      {children}
      {showContent && (
        <CardContent
          {...contentProps}
          className={classNames(styles.content, contentProps.className)}
        >
          {heading && (
            <Typography
              {...headingProps}
              className={classNames(styles.heading, headingProps.className)}
            >
              {heading}
            </Typography>
          )}
          {context && (
            <Typography
              {...contextProps}
              className={classNames(styles.context, contextProps.className)}
            >
              {context}
            </Typography>
          )}
          {content}
        </CardContent>
      )}
      {footer}
      {actions && (
        <CardActions
          {...actionsProps}
          className={classNames(styles.actions, actionsProps.className)}
        >
          {buttons
            ? buttons.map((button) => (
                <Button
                  component={Link}
                  to={button.path}
                  {...buttonProps}
                  className={classNames(
                    styles.button,
                    buttonProps.className,
                    button.className
                  )}
                  color={button.color || buttonProps.color}
                  key={button.id || button.label}
                  size={button.size || buttonProps.size}
                  variant={button.variant || buttonProps.variant}
                >
                  {button.label}
                </Button>
              ))
            : actions}
        </CardActions>
      )}
    </MuiCard>
  );
};

Card.defaultProps = cardDefaultProps;
Card.propTypes = cardPropTypes;
