import { makeStyles } from "@material-ui/core/styles";

export const useDividerStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    divider: {
      backgroundColor: palette.divider,
      height: 2,
      margin: spacing(6, "auto"),
      [breakpoints.up("lg")]: {
        marginBottom: spacing(8),
        marginTop: spacing(8),
      },
      "&.secondary": {
        backgroundColor: palette.secondary.dark,
      },
      "&.thematic": {
        backgroundColor: palette.grey[500],
        marginBottom: spacing(12),
        marginTop: spacing(12),
        [breakpoints.up("lg")]: {
          marginBottom: spacing(16),
          marginTop: spacing(16),
        },
      },
    },
  })
);
