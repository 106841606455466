import React from "react";
import classNames from "classnames";
import { Box, Container } from "@material-ui/core";

import { useSectionStyles } from "./Section.styles";
import { sectionDefaultProps, sectionPropTypes } from "./config";

export const Section = ({
  alignContent,
  alignItems,
  bgcolor,
  children,
  className,
  color,
  component,
  containerProps,
  justifyContent,
  minHeight,
  ...restSectionProps
}) => {
  const styles = useSectionStyles();

  return (
    <Box
      alignContent={alignContent}
      alignItems={alignItems}
      bgcolor={bgcolor}
      className={classNames(styles.section, className)}
      color={color}
      component={component}
      justifyContent={justifyContent}
      minHeight={minHeight}
      {...restSectionProps}
    >
      <Container
        {...sectionDefaultProps.containerProps}
        {...containerProps}
        className={classNames(styles.container, containerProps.className)}
      >
        {children}
      </Container>
    </Box>
  );
};

Section.defaultProps = sectionDefaultProps;
Section.propTypes = sectionPropTypes;
