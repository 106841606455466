import { makeStyles } from "@material-ui/core/styles";

export const useLayoutStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    button: {
      marginTop: spacing(2),
      "&:hover": {
        textDecoration: "none",
      },
      "&.hero": {
        margin: spacing(4, 0),
      },
    },
    context: {
      marginBottom: spacing(2),
    },
    heading: {
      color: palette.text.primary,
      fontWeight: typography.fontWeightExtraBold,
      margin: spacing(1, 0, 2),
      [breakpoints.up("md")]: {
        margin: spacing(2, 0, 3),
      },
    },
    layout: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      overflowX: "hidden",
      "& ::selection": {
        backgroundColor: palette.primary.main,
        color: palette.contrastText,
      },
    },
    link: {
      color: palette.primary.dark,
      margin: spacing(0, 0.75),
      transition: "color 250ms ease-in-out",
      "&:hover": {
        color: palette.primary.contrastText,
      },
    },
    main: {
      flex: 1,
      "& .page-body": {
        paddingBottom: spacing(6),
      },
    },
    subtitle: {
      marginTop: spacing(2),
    },
    title: {
      color: palette.text.primary,
      fontWeight: typography.fontWeightExtraBold,
      marginTop: spacing(6),
    },
  })
);
