import { useStaticQuery, graphql } from "gatsby";

export const useAvatarsQuery = () => {
  const { avatars } = useStaticQuery(graphql`
    query AvatarsQuery {
      avatars: allFile(filter: { absolutePath: { regex: "/avatar/" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 600, quality: 90, traceSVG: { color: "#ced0d4" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
          name
        }
      }
    }
  `);

  return avatars;
};
