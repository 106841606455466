import React from "react";
import Img from "gatsby-image";

// import { decodeFile } from "utils/form";

// renderAirtableImage - renders an image from airtable data.
export const renderAirtableImage = (imageData, imageName, className) => {
  if (!imageData) return null;

  if (
    imageData.hasOwnProperty("localFiles") &&
    imageData.localFiles[0].childImageSharp
  ) {
    return (
      <Img
        alt={imageData.localFiles[0].name || imageName}
        className={className}
        {...imageData.localFiles[0].childImageSharp}
      />
    );
  } else {
    return <img alt={imageName} className={className} src={imageData} />;
  }

  // const { base64, fileType } = JSON.parse(imageData);
  // const decodedImage = decodeFile(base64, fileType);

  // return <img alt={imageName} className={className} src={decodedImage} />;
};
