import { makeStyles } from "@material-ui/core/styles";

export const useCardResourceStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions, typography }) => ({
    actions: {
      justifyContent: "flex-end",
      padding: spacing(2, 4, 4),
    },
    avatar: {
      overflow: "hidden",
      width: "100%",
    },
    card: {
      backgroundColor: palette.background.default,
      border: "2px solid",
      borderColor: palette.grey[500],
      display: "flex",
      flexFlow: "column nowrap",
      minHeight: 250,
      position: "relative",
      transition: transitions.create(["background-color", "border-color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      [breakpoints.up("md")]: {
        minHeight: 450,
      },
      [breakpoints.up("lg")]: {
        minHeight: 375,
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
      padding: spacing(14, 4, 2),
      "&:last-child": {
        paddingBottom: spacing(4),
      },
    },
    context: {
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1.25,
      "&.description": {
        color: palette.text.secondary,
        fontWeight: typography.fontWeightRegular,
        marginTop: spacing(2),
      },
      "&:hover": {
        color: palette.text.secondary,
      },
    },
    header: {
      alignItems: "center",
      left: 0,
      minHeight: spacing(12),
      padding: spacing(4),
      position: "absolute",
      top: 0,
      width: "100%",
      "& .MuiCardHeader-avatar": {
        display: "flex",
        margin: 0,
        width: 50,
      },
    },
    imageLink: {
      width: "100%",
    },
    link: {
      alignItems: "stretch",
      color: palette.grey[700],
      display: "inline-flex",
      flex: "0 0 auto",
      flexFlow: "column nowrap",
      margin: 0,
      marginLeft: spacing(2),
      textDecoration: "none !important",
      transition: transitions.create(
        ["background-color", "border-color", "color"],
        {
          duration: transitions.duration.shortest,
          easing: transitions.easing.easeInOut,
        }
      ),
      "&:hover": {
        backgroundColor: "transparent",
        color: palette.text.secondary,
      },
      "&.active": {
        color: palette.grey[700],
        cursor: "default",
        pointerEvents: "none",
      },
      "&:first-child": {
        marginLeft: 0,
      },
    },
    category: {
      boxShadow: "none !important",
      justifySelf: "flex-end",
      marginLeft: "auto",
    },
  })
);
