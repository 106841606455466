import { makeStyles } from "@material-ui/core/styles";

export const useTextStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    text: {
      flex: "1 100%",
      marginBottom: spacing(2),
      [breakpoints.up("md")]: {
        marginBottom: spacing(3),
      },
      "&.clear-bottom": {
        marginBottom: spacing(4),
        [breakpoints.up("md")]: {
          marginBottom: spacing(6),
        },
      },
      "&.clear-top": {
        marginTop: spacing(2),
        [breakpoints.up("md")]: {
          marginBottom: spacing(3),
        },
      },
      "&.compact": {
        margin: "0 !important",
      },
      "&.compact-top": {
        marginTop: "0 !important",
      },
      "&.cta": {
        margin: spacing(6, "auto"),
        maxWidth: 800,
        [breakpoints.up("md")]: {
          textAlign: "center",
        },
      },
      "&.heading": {
        fontWeight: typography.fontWeightExtraBold,
        lineHeight: 1.5,
        margin: spacing(4, 0, 2),
        [breakpoints.up("md")]: {
          marginTop: spacing(6),
        },
      },
      "&.narrow": {
        maxWidth: 500,
      },
      "&.signature": {
        flex: "1 100%",
        fontStyle: "italic",
        fontWeight: typography.fontWeightMedium,
        lineHeight: 1.25,
        margin: spacing(2, 0),
        [breakpoints.up("sm")]: {
          flex: 1,
        },
        "& .signature--title": {
          display: "block",
          fontStyle: "normal",
          fontWeight: typography.fontWeightRegular,
        },
      },
      "&.title": {
        fontFamily: typography.fontBody,
        fontWeight: typography.fontWeightExtraBold,
        letterSpacing: 0,
        lineHeight: 1,
        marginBottom: 0,
        marginTop: spacing(6),
        [breakpoints.up("md")]: {
          marginTop: 0,
        },
      },
    },
  })
);
