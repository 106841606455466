import { makeStyles } from "@material-ui/core/styles";

export const useStepperStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    label: {
      "& .MuiStepLabel-label": {
        fontSize: typography.caption.fontSize,
        [breakpoints.up("sm")]: {
          fontSize: typography.body2.fontSize,
        },
      },
      "& .MuiStepIcon-text": {
        fill: palette.grey[300],
      },
      "& .MuiStepIcon-active": {
        "& .MuiStepIcon-text": {
          fill: palette.text.primary,
        },
      },
    },
    stepper: {
      backgroundColor: "transparent",
      padding: spacing(3, 0),
      [breakpoints.up("lg")]: {
        padding: spacing(5, 0),
      },
    },
  })
);
