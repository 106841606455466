import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field } from "react-final-form";

// import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

import { FormField } from "../default/FormField";
import { defaultProps, propTypes } from "../config";
import { useFieldState, showError } from "../utils";

import { useFieldQuillStyles } from "./FieldQuill.styles";

const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

// TODO:
// - set focused using meta active from rff
// - add undo and redo buttons after clean button
// - add fallback for SSR when ReactQuill is used
//   - https://github.com/zenoamaro/react-quill/issues/122
//   - https://github.com/zenoamaro/react-quill/issues/389
// - (optional) custom mui toolbar with additional text format buttons

export const FieldQuill = ({ fieldProps, quillProps, ...rest }) => {
  const styles = useFieldQuillStyles();
  const quillRef = useRef(null);
  const field = useFieldState(rest.name);
  const isError = showError(field);
  const [focused, setFocus] = useState(false);

  return (
    <FormField focused={focused} ref={quillRef} {...rest}>
      <Field name={rest.name} {...fieldProps}>
        {({
          input: { name, value, onChange, ...restInput },
          meta: { active },
        }) => {
          setFocus(active);

          return (
            <ReactQuill
              {...quillProps}
              {...restInput}
              className={classNames(styles.quill, quillProps.className, {
                active,
                error: isError,
              })}
              defaultValue={value}
              name={name}
              onChange={(value) => {
                if (onChange) {
                  onChange(value);
                }
              }}
            />
          );
        }}
      </Field>
    </FormField>
  );
};

FieldQuill.defaultProps = {
  ...defaultProps,
  fieldProps: undefined,
  quillProps: {
    className: undefined,
    formats: [
      "bold",
      "italic",
      "underline",
      "strike",
      "link",
      "header",
      "blockquote",
      "code",
      "list",
      "bullet",
      "indent",
      "clean",
    ],
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike", "link"],
        [
          { header: [1, 2, false] },
          "blockquote",
          "code",
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["clean"],
      ],
    },
  },
};

FieldQuill.propTypes = {
  ...propTypes,
  fieldProps: PropTypes.any, // https://final-form.org/docs/react-final-form/types/FieldProps
  quillProps: PropTypes.any, // https://github.com/zenoamaro/react-quill#props
};
