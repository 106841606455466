import PropTypes from "prop-types";
import { makeValidate } from "mui-rff";

import { companyInfoSchema, jobInfoSchema } from "utils/validate";

/**
 * form config
 */

// initialValues - default form values with proper types.
export const initialValues = {
  companyDescription: "",
  companyEmail: "",
  companyImages: "",
  companyLogo: "",
  companyMission: "",
  companyName: "",
  companyUrl: "",
  hiringDescription: "",
  hiringManager: "",
  hiringStartDate: "",
  jobApplication: "",
  jobCategory: "",
  jobDescription: "",
  jobRegions: [],
  jobTitle: "",
  jobType: "",
};

// subscription - form subscription config.
export const subscription = {
  error: true,
  modified: true,
  pristine: true,
  submitError: true,
  submitting: true,
  touched: true,
};

// validateSteps - array of validators in the same order as the form wizard steps.
export const validateSteps = [
  makeValidate(jobInfoSchema),
  makeValidate(companyInfoSchema),
];

/**
 * propTypes config
 */

export const fieldPropTypes = {
  companyDescription: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  companyEmail: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
  }),
  companyImages: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  companyLogo: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  companyMission: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  companyName: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
  companyUrl: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  hiringDescription: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  hiringManager: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  hiringStartDate: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  jobApplication: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
  jobCategory: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOf([
          "Art",
          "Audio",
          "Design",
          "Development",
          "Social",
          "Video",
          "Writing",
        ]),
        value: PropTypes.oneOf([
          "Art",
          "Audio",
          "Design",
          "Development",
          "Social",
          "Video",
          "Writing",
        ]),
      })
    ),
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
  jobDescription: PropTypes.shape({
    label: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  jobRegions: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOf([
          "Anywhere",
          "Asia",
          "Canada",
          "Europe",
          "US",
          "South America",
          "Africa",
        ]),
        value: PropTypes.oneOf([
          "Anywhere",
          "Asia",
          "Canada",
          "Europe",
          "US",
          "South America",
          "Africa",
        ]),
      })
    ),
    helperText: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
  jobTitle: PropTypes.shape({
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
  jobType: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOf(["Contract", "Full-time", "Part-time"]),
        value: PropTypes.oneOf(["Contract", "Full-time", "Part-time"]),
      })
    ),
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
};

export const valuePropTypes = {
  companyDescription: PropTypes.string,
  companyEmail: PropTypes.string,
  companyImages: PropTypes.array,
  companyLogo: PropTypes.array,
  companyMission: PropTypes.string,
  companyName: PropTypes.string,
  companyUrl: PropTypes.string,
  hiringDescription: PropTypes.string,
  hiringManager: PropTypes.string,
  hiringStartDate: PropTypes.string,
  jobApplication: PropTypes.string,
  jobCategory: PropTypes.string,
  jobDescription: PropTypes.string,
  jobRegions: PropTypes.arrayOf(PropTypes.string),
  jobTitle: PropTypes.string,
  jobType: PropTypes.string,
};
