import { makeStyles } from "@material-ui/core/styles";

export const useBrandStyles = makeStyles(
  ({ palette, transitions, typography }) => ({
    brand: {
      color: palette.primary.contrastText,
      display: "inline-flex",
      textDecoration: "none",
      transition: transitions.create(["color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&:hover": {
        color: palette.primary.main,
        textDecoration: "none",
      },
      "&.light": {
        color: palette.common.white,
        "&:hover": {
          color: palette.primary.main,
        },
      },
      "&.primary": {
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.dark,
        },
      },
      "&.primary-contrast": {
        color: palette.text.secondary,
        "&:hover": {
          color: palette.primary.contrastText,
        },
      },
      "&.secondary": {
        color: palette.secondary.main,
        "&:hover": {
          color: palette.secondary.dark,
        },
      },
    },
    brandName: {
      fontWeight: typography.fontWeightExtraBold,
      fontFamily: typography.fontBody,
      letterSpacing: -1.5,
      lineHeight: 0.9,
      margin: 0,
      textTransform: "uppercase",
      "& .stack": {
        display: "block",
        lineHeight: 0.9,
      },
    },
  })
);
