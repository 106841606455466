import PropTypes from "prop-types";

import {
  sectionSplitDefaultProps,
  sectionSplitPropTypes,
} from "../split/config";

export const sectionCTADefaultProps = {
  ...sectionSplitDefaultProps,
  card: null,
  cardProps: {
    className: null,
    headingProps: {
      component: "h4",
      variant: "h2",
    },
  },
  content: null,
  heading: null,
  image: null,
  leftProps: {
    md: 5,
    xs: 12,
  },
  rightProps: {
    md: 7,
    xs: 12,
  },
};

export const sectionCTAPropTypes = {
  ...sectionSplitPropTypes,
  card: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  cardProps: PropTypes.shape({
    className: PropTypes.string,
    content: PropTypes.string,
    heading: PropTypes.string,
    headingProps: PropTypes.shape({
      className: PropTypes.string,
    }),
  }), // cardProps: `components/card/default/_config`
  content: PropTypes.node,
  heading: PropTypes.string,
  image: PropTypes.shape({
    className: PropTypes.string,
    childImageSharp: PropTypes.shape({
      base64: PropTypes.string,
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
};
