import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Instagram } from "@material-ui/icons";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { Link } from "components";
import { Brand } from "containers";

import { useLayoutFooterStyles } from "./Footer.styles";

export const LayoutFooter = ({
  copyright,
  isHidden,
  memorial,
  updated,
  name,
  organization,
  socialMedia,
  stack,
  subscribe,
  ...restLayoutFooterProps
}) => {
  const styles = useLayoutFooterStyles();

  if (isHidden) return null;

  const brandProps = {
    brandNameProps: {
      component: "span",
      variant: "h3",
    },
    className: "primary-contrast",
  };

  const renderCopyright = () => {
    if (copyright && organization) {
      const copyrightMessage = copyright.split(organization.name);

      return (
        <Typography className={styles.text} variant="body2">
          {copyrightMessage[0]}
          <OutboundLink
            className={classNames(styles.link, "inline")}
            href={organization.url}
            target="_blank"
          >
            {organization.name}
          </OutboundLink>
          {copyrightMessage[1]}
        </Typography>
      );
    }

    return `© ${new Date().getFullYear()}. ${name}.`;
  };

  return (
    <Box
      component="footer"
      className={styles.footer}
      {...restLayoutFooterProps}
    >
      <Container>
        <Brand {...brandProps} />
        <Grid className={styles.nav} component="nav" container>
          <Grid className={styles.navList} component={List} item md={3} xs={12}>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/jobs"
                variant="h5"
              >
                Find a Job
              </Typography>
            </ListItem>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/jobs/new"
                variant="h5"
              >
                Post a Job
              </Typography>
            </ListItem>
            {subscribe && (
              <ListItem className={styles.navItem}>
                <Typography
                  className={styles.link}
                  component={OutboundLink}
                  href={subscribe}
                  target="_blank"
                  variant="h5"
                >
                  Subscribe
                </Typography>
              </ListItem>
            )}
          </Grid>
          <Grid className={styles.navList} component={List} item md={3} xs={12}>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/about"
                variant="h5"
              >
                About
              </Typography>
            </ListItem>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/resources"
                variant="h5"
              >
                Resources
              </Typography>
            </ListItem>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/blog"
                variant="h5"
              >
                Blog
              </Typography>
            </ListItem>
          </Grid>
          <Grid className={styles.navList} component={List} item md={3} xs={12}>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/terms"
                variant="h5"
              >
                Terms of Service
              </Typography>
            </ListItem>
            <ListItem className={styles.navItem}>
              <Typography
                className={styles.link}
                component={Link}
                to="/privacy"
                variant="h5"
              >
                Privacy Policy
              </Typography>
            </ListItem>
          </Grid>
          {socialMedia && (
            <Grid
              className={classNames(styles.navList, "social-media")}
              component={List}
              item
              md={3}
              xs={12}
            >
              {socialMedia.instagram && (
                <ListItem className={styles.navItem}>
                  <Typography
                    className={styles.link}
                    component={OutboundLink}
                    href={`https://instagram.com/${socialMedia.instagram}`}
                    target="_blank"
                    variant="h5"
                  >
                    <Instagram />
                  </Typography>
                </ListItem>
              )}
              {/* Note: hiding twitter until account beomces active */}
              {/* {socialMedia.twitter && (
                <ListItem className={styles.navItem}>
                  <Typography
                    className={styles.link}
                    component={OutboundLink}
                    href={`https://twitter.com/${socialMedia.twitter}`}
                    target="_blank"
                    variant="h5"
                  >
                    <Twitter />
                  </Typography>
                </ListItem>
              )} */}
            </Grid>
          )}
        </Grid>
        {renderCopyright()}
        {stack && (
          <Typography className={styles.text} variant="body2">
            Stack: {stack}
          </Typography>
        )}
        {memorial && (
          <Typography className={styles.text} variant="body2">
            {memorial}
          </Typography>
        )}
        {updated && (
          <Typography className={styles.text} variant="body2">
            {updated}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

LayoutFooter.defaultProps = {
  copyright: null,
  isHidden: false,
  memorial: null,
  updated: null,
  organization: null,
  socialMedia: null,
  stack: null,
  subscribe: null,
};

LayoutFooter.propTypes = {
  copyright: PropTypes.string,
  isHidden: PropTypes.bool,
  memorial: PropTypes.string,
  updated: PropTypes.string,
  name: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  socialMedia: PropTypes.shape({
    instagram: PropTypes.string,
    twitter: PropTypes.string,
  }),
  stack: PropTypes.string,
  subscribe: PropTypes.string,
};
