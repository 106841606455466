import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Grid } from "@material-ui/core";

import { CardCTA, Header } from "components";
import { getRandomId } from "utils/form";

import { useHeaderCTAStyles } from "./HeaderCTA.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

export const HeaderCTA = ({
  captionProps,
  cardGridProps,
  cards,
  cardsProps,
  children,
  className,
  contextProps,
  headingProps,
  ...restHeaderProps
}) => {
  const styles = useHeaderCTAStyles();

  return (
    <Header
      {...restHeaderProps}
      captionProps={{
        ...captionProps,
        className: classNames(styles.caption, captionProps.className),
      }}
      className={className}
      contextProps={{
        ...contextProps,
        className: classNames(styles.context, contextProps.className),
      }}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
    >
      {children}
      {cards && cards.length > 0 && (
        <Grid
          container
          {...cardsProps}
          className={classNames(styles.cards, cardsProps.className)}
        >
          {cards.map((card) => (
            <Grid
              item
              {...cardGridProps}
              {...card.gridProps}
              className={classNames(
                styles.card,
                card.gridProps
                  ? card.gridProps.className
                  : cardGridProps.className
              )}
              key={card.id || getRandomId()}
            >
              {card.component ? card.component : <CardCTA {...card} />}
            </Grid>
          ))}
        </Grid>
      )}
    </Header>
  );
};

HeaderCTA.defaultProps = {
  ...headerDefaultProps,
  alignContent: "center",
  alignItems: "center",
  captionProps: {
    ...headerDefaultProps.captionProps,
  },
  cardGridProps: {
    className: null,
    component: "li",
    xs: 12,
    md: 6,
  },
  cards: [],
  cardsProps: {
    className: null,
    component: "ul",
    spacing: 2,
  },
  containerProps: {
    ...headerDefaultProps.containerProps,
  },
  contextProps: {
    ...headerDefaultProps.contextProps,
    variant: "h4",
  },
  headingProps: {
    ...headerDefaultProps.headingProps,
    variant: "h1",
  },
  minHeight: 0,
};

HeaderCTA.propTypes = {
  ...headerPropTypes,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        label: PropTypes.string,
        path: PropTypes.string,
      }),
      context: PropTypes.string,
      heading: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
            sizes: PropTypes.string,
          }),
        }),
        className: PropTypes.string,
      }),
    })
  ),
};
