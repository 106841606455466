import { DESCRIPTION_MIN_LENGTH } from "./validate";

// getDescription - get description to render data from markdown/mdx fields for different states.
export const getDescription = (description) => {
  if (!description) return null;

  const isMDX = description.childMdx || description.raw;
  if (isMDX) {
    if (description.raw.length > DESCRIPTION_MIN_LENGTH)
      return description.childMdx.body;

    return null;
  } else {
    const cleanBody = description.replace(/<(.|\n)*?>/g, "").trim();
    if (cleanBody.length > 0) return description;

    return null;
  }
};

// getPrice - get price from unit amount and currency code values.
export const getPrice = (amount, currency) => {
  if (!amount) return null;
  const slicedAmount = amount.slice(0, amount.length - 2);

  switch (currency) {
    case "usd":
    default:
      return `$${slicedAmount}`;
  }
};

// getRandomId - create a random filename for uploading images.
// NOTE: This is good but you can also look for a library for things like this:
// checkout out ramdajs - https://ramdajs.com/
// - its a good way to learn about currying function in js and teaches you some js fundamentals
export const getRandomId = (length = 16) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  let result = "";
  let i = 0;
  for (i; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

// getUrl - check if url is missing https prefix or is its an email address and return correct url.
export const getUrl = (url) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!url) return null;
  else if (url.match(emailRegex)) return `mailto:${url}`;
  else if (!url.match(/^[a-zA-Z]+:\/\//)) return `https://${url}`;
  return url;
};

export const decodeFile = (base64, fileType) => {
  if (typeof window === "undefined") return null;

  const bin = atob(base64);
  const bytearray2 = new Uint8Array(
    bin.split("").map((el) => {
      return el.charCodeAt(0);
    })
  );
  const bytebuffer2 = bytearray2.buffer;
  const blob2 = new Blob([bytebuffer2], { type: fileType });
  const url2 = URL.createObjectURL(blob2);

  return url2;
};

// TODO: refactor with code from DropzoneInput
// const encodeFile = (file) => {
//   return new Promise((res) => {
//     var reader = new FileReader();
//     reader.addEventListener("loadend", function (e) {
//       const byteArray = new Uint8Array(reader.result);
//       // this is more robust, for larger images.
//       const binString = Array.from(byteArray)
//         .map((el) => String.fromCharCode(el))
//         .join("");
//       const base64 = btoa(binString);
//       res(base64);
//     });
//     // File reading is event driven/asynchronous. Must use promise/await.
//     reader.readAsArrayBuffer(file);
//   });
// };
