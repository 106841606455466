import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button as MuiButton } from "@material-ui/core";

import { useButtonStyles } from "./Button.styles";

export const Button = ({ children, className, ...restButtonProps }) => {
  const styles = useButtonStyles();

  return (
    <MuiButton
      {...restButtonProps}
      className={classNames(styles.button, className)}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: "contained",
};

Button.propTypes = {
  children: PropTypes.elementType,
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["large", "medium", "small"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  // restButtonProps: https://material-ui.com/api/button/#props
};
