import React from "react";
import classNames from "classnames";

import { Header } from "components";

import { useHeaderTitleStyles } from "./HeaderTitle.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

export const HeaderTitle = ({
  children,
  className,
  headingProps,
  ...restHeaderProps
}) => {
  const styles = useHeaderTitleStyles();

  return (
    <Header
      {...restHeaderProps}
      className={classNames(styles.header, className)}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
    >
      {children}
    </Header>
  );
};

HeaderTitle.defaultProps = {
  ...headerDefaultProps,
  alignContent: "flex-start",
  alignItems: "flex-start",
  headingProps: {
    ...headerDefaultProps.headingProps,
    color: "textPrimary",
    component: "h1",
    variant: "h2",
  },
  minHeight: 0,
};

HeaderTitle.propTypes = headerPropTypes;
