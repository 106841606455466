import { makeStyles } from "@material-ui/core/styles";

export const useSectionJobsStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    filter: {},
    heading: {
      color: palette.text.primary,
      fontWeight: typography.fontWeightMedium,
      margin: spacing(1, 0, 2),
      [breakpoints.up("md")]: {
        margin: spacing(2, 0, 3),
      },
    },
    job: {
      zIndex: 1,
    },
    jobs: {
      listStyle: "none",
      marginBottom: spacing(2),
      padding: 0,
      zIndex: 2,
    },
    link: {
      display: "inline-flex",
    },
    mascot: {
      position: "absolute !important",
      right: -8,
      top: -90,
      transform: "scaleX(-1)",
      width: 140,
      zIndex: 1,
      [breakpoints.up("sm")]: {
        top: -170,
        width: 200,
      },
      [breakpoints.up("lg")]: {
        right: -25,
        top: -275,
        width: 280,
      },
      "&.mascot-audio": {
        right: 0,
        [breakpoints.up("lg")]: {
          right: spacing(-1),
          top: spacing(-35),
        },
      },
      "&.mascot-creative": {
        right: -6,
        [breakpoints.up("sm")]: {
          right: -8,
        },
        [breakpoints.up("lg")]: {
          right: spacing(-2),
          top: spacing(-33),
        },
      },
      "&.mascot-design": {
        right: spacing(-0.25),
        [breakpoints.up("sm")]: {
          right: spacing(-0.5),
          top: -160,
        },
        [breakpoints.up("lg")]: {
          right: spacing(-1),
          top: -275,
        },
      },
      "&.mascot-development": {
        [breakpoints.up("sm")]: {
          right: spacing(-3),
          top: -165,
        },
        [breakpoints.up("lg")]: {
          right: spacing(-5),
          top: spacing(-34),
        },
      },
      "&.mascot-social": {
        right: spacing(-2.5),
        top: spacing(-17.5),
        [breakpoints.up("sm")]: {
          right: spacing(-3.5),
          top: spacing(-26),
        },
        [breakpoints.up("lg")]: {
          right: spacing(-6),
          top: spacing(-34),
        },
      },
      "&.mascot-writing": {
        right: spacing(-3.5),
        top: spacing(-17),
        [breakpoints.up("sm")]: {
          right: spacing(-4.5),
          top: spacing(-26),
        },
        [breakpoints.up("lg")]: {
          right: spacing(-7.5),
          top: spacing(-39),
        },
      },
      ".no-jobs &": {
        top: "auto !important",
        bottom: spacing(-5),
        [breakpoints.up("sm")]: {
          bottom: spacing(-8),
        },
        [breakpoints.up("md")]: {
          bottom: spacing(-10),
        },
        [breakpoints.up("lg")]: {
          bottom: spacing(-12),
        },
      },
    },
    section: {
      "&.no-jobs": {
        marginBottom: spacing(4),
        marginTop: spacing(8),
        zIndex: 1,
      },
      "&.cta": {
        marginBottom: spacing(6),
        marginTop: 0,
        paddingTop: spacing(4),
        [breakpoints.up("sm")]: {
          marginBottom: spacing(18),
        },
      },
    },
  })
);
