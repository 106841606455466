import React from "react";
import classNames from "classnames";
import { ArrowBack } from "@material-ui/icons";

import { Header } from "components";

import { useHeaderArticleStyles } from "./HeaderArticle.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

export const HeaderArticle = ({
  buttonProps,
  buttonsProps,
  children,
  className,
  contextProps,
  headingProps,
  ...restHeaderProps
}) => {
  const styles = useHeaderArticleStyles();

  return (
    <Header
      {...restHeaderProps}
      buttonProps={{
        ...buttonProps,
        className: classNames(styles.button, buttonProps.className),
      }}
      buttonsProps={{
        ...buttonsProps,
        className: classNames(styles.buttons, buttonsProps.className),
      }}
      className={classNames(styles.header, className)}
      contextProps={{
        ...contextProps,
        className: classNames(styles.context, contextProps.className),
      }}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
    >
      {children}
    </Header>
  );
};

HeaderArticle.defaultProps = {
  ...headerDefaultProps,
  buttonProps: {
    color: "inherit",
    size: "small",
    startIcon: <ArrowBack />,
    variant: "outlined",
  },
  buttonsProps: {
    ...headerDefaultProps.buttonsProps,
    justifyContent: "flext-start",
  },
  containerProps: {
    ...headerDefaultProps.containerProps,
    disableGutters: true,
    maxWidth: false,
  },
  contextProps: {
    ...headerDefaultProps.contextProps,
    variant: "subtitle1",
  },
  headingProps: {
    ...headerDefaultProps.headingProps,
    variant: "h2",
  },
  image: null,
  minHeight: 0,
};

HeaderArticle.propTypes = headerPropTypes;
