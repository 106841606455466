import { makeStyles } from "@material-ui/core/styles";

export const useCardNextStyles = makeStyles(
  ({ palette, shape, spacing, transitions, typography }) => ({
    card: {
      backgroundColor: palette.background.default,
      border: "2px solid",
      borderColor: palette.text.secondary,
      borderRadius: shape.borderRadius,
      display: "flex",
      transition: transitions.create(["background-color", "border-color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&:hover": {
        backgroundColor: palette.primary.main,
        borderColor: palette.secondary.dark,
        textDecoration: "none",
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
      justifyContent: "center",
      padding: spacing(8, 4),
    },
    link: {
      alignItems: "stretch",
      display: "flex",
      flexFlow: "column nowrap",
      textDecoration: "none !important",
    },
    nextLabel: {
      margin: spacing(4, 0, 0),
      textDecoration: "underline",
    },
    title: {
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1.25,
      margin: 0,
    },
  })
);
