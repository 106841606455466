import { makeStyles } from "@material-ui/core/styles";

export const useCardCTAStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, typography }) => ({
    actions: {
      bottom: spacing(-3),
      left: spacing(5),
      padding: 0,
      position: "absolute",
    },
    card: {
      alignContent: "flex-start",
      alignItems: "flex-start",
      borderColor: palette.grey[600],
      backgroundColor: palette.background.default,
      border: "2px solid",
      borderRadius: shape.borderRadius,
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      overflow: "initial",
      position: "relative",
      [breakpoints.up("md")]: {
        minHeight: 280,
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
      padding: spacing(3, 5, 4),
      "& p": {
        marginBottom: spacing(2),
      },
    },
    heading: {
      fontWeight: typography.fontWeightExtraBold,
      color: palette.text.secondary,
    },
    image: {
      left: -100,
      position: "absolute !important",
      top: -100,
      transform: "rotate(40deg)",
      width: 200,
      [breakpoints.up("md")]: {
        left: -25,
        top: -275,
        transform: "none",
        width: 250,
        zIndex: -1,
      },
      "&.align-right": {
        left: "auto",
        right: -95,
        top: -125,
        transform: "rotate(-40deg)",
        [breakpoints.up("md")]: {
          right: -25,
          top: -305,
          transform: "none",
        },
      },
      "&.featured": {
        display: "none",
        [breakpoints.up("sm")]: {
          bottom: -200,
          display: "block",
          left: "auto",
          maxWidth: 800,
          top: "auto",
          transform: "none",
          width: "100%",
        },
        [breakpoints.up("md")]: {
          bottom: -215,
        },
        [breakpoints.up("lg")]: {
          bottom: -230,
        },
      },
    },
  })
);
