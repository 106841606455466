import { makeStyles } from "@material-ui/core/styles";

export const useLayoutHeaderStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, transitions, typography }) => ({
    button: {
      marginLeft: spacing(1.5),
      "&.no-shadow": {
        boxShadow: "none",
      },
    },
    link: {
      borderRadius: shape.borderRadius,
      color: palette.text.primary,
      fontWeight: typography.fontWeightRegular,
      letterSpacing: 0,
      margin: spacing(0, 3),
      textDecoration: "none !important",
      transition: transitions.create(["color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&:hover, &.active": {
        color: palette.text.secondary,
        textDecoration: "underline !important",
      },
    },
    nav: {
      alignContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexFlow: "row nowrap",
      paddingLeft: spacing(2),
      "&.right": {
        justifyContent: "flex-end",
      },
    },
    menuButton: {
      backgroundColor: palette.secondary.dark,
      borderRadius: shape.borderRadius,
      boxShadow: "-9px -9px 16px #fff, 9px 9px 16px rgba(196, 196, 196, .5)",
      marginLeft: spacing(2),
      padding: spacing(2.35),
      transition: transitions.create(["background-color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&:hover": {
        backgroundColor: palette.primary.main,
      },
      "&.primary": {
        backgroundColor: palette.secondary.dark,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: palette.primary.contrastText,
        },
      },
    },
    toolbar: {
      borderBottom: "2px solid",
      borderColor: palette.divider,
      justifyContent: "space-between",
      padding: spacing(3, 0),
      [breakpoints.up("lg")]: {
        paddingBottom: spacing(4),
        paddingTop: spacing(4),
      },
    },
  })
);
