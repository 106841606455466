import { makeStyles } from "@material-ui/core/styles";

export const useDropzoneInputStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing }) => ({
    container: {
      [breakpoints.up("sm")]: {
        flexFlow: "row nowrap",
      },
      "&.multiple": {
        flexFlow: "column",
      },
    },
    dropzone: {
      alignItems: "center",
      backgroundColor: palette.background.default,
      borderRadius: shape.borderRadius,
      boxShadow: `0 0 1px 1px inset ${palette.secondary.main}`,
      display: "flex",
      flex: 1,
      justifyContent: "center",
      minHeight: 150,
      outline: "none",
      padding: spacing(2, 1.5),
      textAlign: "center",
      "&:active, &:focus": {
        boxShadow: `0 0 1px 2px inset ${palette.primary.dark}`,
      },
      "&.disabled": {
        boxShadow: `0 0 1px 1px inset ${palette.grey[400]}`,
        cursor: "not-allowed",
      },
    },
    img: {
      margin: "auto",
      maxWidth: 200,
      width: "100%",
    },
    message: {
      color: palette.grey[700],
      "&.active": {
        color: palette.secondary.main,
      },
      "&.disabled": {
        color: palette.grey[400],
      },
      "&.error": {
        color: palette.error.main,
      },
      "&.success": {
        color: palette.success.main,
      },
    },
    thumbs: {
      marginTop: spacing(1),
      width: "100%",
      [breakpoints.up("sm")]: {
        flex: "none",
        marginLeft: spacing(1),
        marginTop: 0,
        maxWidth: 200,
      },
    },
    thumb: {
      borderColor: palette.secondary.main,
      display: "flex",
      flexFlow: "column nowrap",

      "&:active, &:focus": {
        borderColor: palette.primary.dark,
      },
      marginTop: 0,
      marginLeft: 7,
      "&.multiple": {
        marginLeft: 0,
        marginTop: 6,
        height: "100%",
      },
    },
    thumbActions: {
      backgroundColor: palette.grey[200],
      justifyContent: "space-between",
    },
    thumbRemove: {
      transition: "color 250ms ease-in-out",
      "&:hover": {
        color: palette.error.main,
      },
    },
  })
);
