import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";

import { Card, SectionSplit } from "components";

import { useSectionCTAStyles } from "./SectionCTA.styles";
import { sectionCTADefaultProps, sectionCTAPropTypes } from "./config";

export const SectionCTA = ({
  card,
  cardProps,
  children,
  className,
  content,
  heading,
  image,
  left,
  right,
  ...restSectionProps
}) => {
  const styles = useSectionCTAStyles();

  const showCard = !!content || !!heading;

  const renderImage = () => {
    if (!image) return null;

    if (image.childImageSharp) {
      return (
        <Img
          {...image.childImageSharp}
          alt={image.name}
          className={classNames(styles.image, image.className)}
        />
      );
    }

    return image;
  };

  const renderCard = () => {
    if (!card && !showCard) return null;

    if (showCard) {
      return (
        <Card
          {...cardProps}
          className={classNames(styles.card, cardProps.className)}
          content={content}
          heading={heading}
        />
      );
    }

    return card;
  };

  return (
    <SectionSplit
      left={renderImage()}
      right={renderCard()}
      {...restSectionProps}
      className={classNames(styles.section, className)}
    >
      {children}
    </SectionSplit>
  );
};

SectionCTA.defaultProps = sectionCTADefaultProps;
SectionCTA.propTypes = sectionCTAPropTypes;
