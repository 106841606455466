import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { Button, Link, resourceCategoryTypes } from "components";

import { useCardResourceStyles } from "./CardResource.styles";

export const CardResource = ({
  category,
  contentProps,
  children,
  content,
  className,
  description,
  elevation,
  headerProps,
  id,
  image,
  link,
  title,
  ...restCardResourceProps
}) => {
  const styles = useCardResourceStyles();

  return (
    <Card
      elevation={elevation}
      {...restCardResourceProps}
      className={classNames(styles.card, className)}
    >
      <CardHeader
        action={
          link && (
            <IconButton
              className={styles.link}
              component={OutboundLink}
              href={link}
              target="_blank"
            >
              <OpenInNew />
            </IconButton>
          )
        }
        avatar={
          image &&
          link &&
          (image.localFiles[0]?.childImageSharp ? (
            <OutboundLink className={styles.imageLink} href={link}>
              <Img
                alt={image.localFiles[0]?.name || title}
                className={styles.avatar}
                {...image.localFiles[0]?.childImageSharp}
              />
            </OutboundLink>
          ) : (
            <Img
              alt={image.localFiles[0]?.name || title}
              className={styles.avatar}
              {...image.localFiles[0]?.childImageSharp}
            />
          ))
        }
        {...headerProps}
        className={classNames(styles.header, headerProps.className)}
      />
      <CardContent
        {...contentProps}
        className={classNames(styles.content, contentProps.className)}
      >
        <Typography className={styles.context} component="h4" variant="h4">
          {title}
        </Typography>
        <Typography
          className={classNames(styles.context, "description")}
          component="p"
          variant="body1"
        >
          {description}
        </Typography>
        {content}
      </CardContent>
      {children}
      <CardActions className={styles.actions} disableSpacing>
        {/* <IconButton
          className={styles.link}
          disabled
          size="small"
          aria-label="add to favorites"
        >
          <FavoriteBorder />
        </IconButton>
        <Button
          className={styles.link}
          disabled
          disableRipple
          size="small"
          variant="text"
        >
          Share
        </Button> */}
        <Button
          activeClassName="active"
          className={classNames(styles.link, styles.category)}
          component={Link}
          to={`/resources/${category.split(" ").join("-").toLowerCase()}`}
          size="small"
          variant="outlined"
        >
          {category}
        </Button>
      </CardActions>
    </Card>
  );
};

CardResource.defaultProps = {
  className: null,
  contentProps: {
    className: null,
    component: "section",
  },
  elevation: 0,
  headerProps: {
    className: null,
    component: "header",
  },
};

CardResource.propTypes = {
  category: resourceCategoryTypes,
  className: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      localFiles: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              base64: PropTypes.string,
              aspectRatio: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
        })
      ),
    }),
    PropTypes.string,
  ]),
  link: PropTypes.string,
  title: PropTypes.string,
  // ...restCardProps: https://material-ui.com/api/card/#props
};
