import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormWizard } from "components";
// import { getPrice } from "utils/form";
import { useStripePriceQuery } from "graphql";

import {
  CompanyStep,
  JobStep,
  HiringStep,
  PayStep,
  PreviewStep,
} from "./components";
import {
  fieldPropTypes,
  initialValues,
  subscription,
  validateSteps,
} from "./config";
import { useNewJobFormQuery } from "./query";

// TODO: get STRIPE_PUBLISHABLE_KEY to work on client without GATSBY prefix
// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
// const turndownService = new TurndownService();

// TODO:
// - use a private netlify function to convert html to markdown (no clientside data transforms)
// - create graphql fragment for new job form query
// - add stripe back after initial marketing is done

export const NewJobForm = () => {
  const { action, buttons, fields, method, name, steps } = useNewJobFormQuery();
  const {
    companyDescription,
    companyEmail,
    companyImages,
    companyLogo,
    companyMission,
    companyName,
    companyUrl,
    hiringDescription,
    hiringManager,
    hiringStartDate,
    jobApplication,
    jobCategory,
    jobDescription,
    jobRegions,
    jobTitle,
    jobType,
  } = fields;
  const { id } = useStripePriceQuery();
  // const total = getPrice(unit_amount_decimal, currency);

  const [logoImages, setLogoImages] = useState([]);
  const [compImages, setCompImages] = useState([]);

  return (
    <FormWizard
      buttons={buttons}
      initialValues={initialValues}
      name={name}
      steps={steps}
      subscription={subscription}
      validate={validateSteps}
      method={method}
      action={action}
      compImages={compImages}
      logoImages={logoImages}
      id={id}
    >
      <JobStep
        fields={{
          jobApplication,
          jobCategory,
          jobDescription,
          jobRegions,
          jobTitle,
          jobType,
        }}
      />
      <CompanyStep
        fields={{
          companyDescription,
          companyEmail,
          companyImages,
          companyLogo,
          companyMission,
          companyName,
          companyUrl,
        }}
        logoImages={logoImages}
        setLogoImages={setLogoImages}
        compImages={compImages}
        setCompImages={setCompImages}
      />
      <HiringStep
        fields={{
          hiringDescription,
          hiringManager,
          hiringStartDate,
        }}
      />
      <PreviewStep compImages={compImages} logoImages={logoImages} />
      <PayStep />
    </FormWizard>
  );
};

NewJobForm.propTypes = {
  data: PropTypes.shape({
    form: PropTypes.shape({
      form: PropTypes.shape({
        action: PropTypes.string,
        buttons: PropTypes.shape({
          next: PropTypes.string,
          prev: PropTypes.string,
          submit: PropTypes.string,
        }),
        fields: PropTypes.shape(fieldPropTypes),
        method: PropTypes.string,
        name: PropTypes.string,
        steps: PropTypes.arrayOf(PropTypes.string),
      }),
      id: PropTypes.string,
    }),
    prices: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            currency: PropTypes.string,
            id: PropTypes.string,
            unit_amount_decimal: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};
