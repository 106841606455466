import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Filter, Header, Text } from "components";

import { useHeaderFilterStyles } from "./HeaderFilter.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

export const HeaderFilter = ({
  children,
  className,
  containerProps,
  filters,
  footer,
  heading,
  headingProps,
  location,
  ...restHeaderProps
}) => {
  const styles = useHeaderFilterStyles();

  return (
    <Header
      {...restHeaderProps}
      containerProps={{
        ...containerProps,
        className: classNames(styles.container, containerProps?.className),
      }}
      className={classNames(styles.header, className)}
    >
      {heading && (
        <Text
          {...headingProps}
          className={classNames(styles.heading, headingProps?.className)}
        >
          {heading}
        </Text>
      )}
      {children}
      {filters && <Filter filters={filters} location={location} />}
      {footer}
    </Header>
  );
};

HeaderFilter.defaultProps = {
  ...headerDefaultProps,
  alignContent: "center",
  alignItems: "center",
  containerProps: {
    ...headerDefaultProps.containerProps,
    maxWidth: "md",
  },
  minHeight: 0,
};

HeaderFilter.propTypes = {
  ...headerPropTypes,
  filters: PropTypes.oneOfType(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        path: PropTypes.string,
      }),
      PropTypes.bool
    )
  ),
  footer: PropTypes.node,
};
