import { makeStyles } from "@material-ui/core/styles";

export const useHeaderArticleStyles = makeStyles(({ spacing }) => ({
  buttons: {
    marginBottom: spacing(1),
    marginTop: spacing(2),
  },
  context: {
    marginBottom: spacing(1),
    marginTop: spacing(1),
  },
  header: {
    padding: spacing(2, 0),
  },
  heading: {
    marginBottom: spacing(1),
    marginTop: spacing(4),
  },
}));
