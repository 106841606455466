import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box } from "@material-ui/core";

import { Header } from "components";

import { useHeaderJobStyles } from "./HeaderJob.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

export const HeaderJob = ({
  captionProps,
  children,
  className,
  containerProps,
  context,
  contextProps,
  heading,
  headingProps,
  applyJob,
  isPreview,
  logo,
  logoProps,
  ...restHeaderProps
}) => {
  const styles = useHeaderJobStyles();
  return (
    <Header
      {...restHeaderProps}
      captionProps={{
        ...captionProps,
        className: styles.caption,
      }}
      className={classNames(styles.header, className, { preview: isPreview })}
      containerProps={{
        ...containerProps,
        className: classNames(styles.container),
      }}
      context={context}
      contextProps={{
        ...contextProps,
        className: classNames(styles.context, contextProps.className),
      }}
      heading={heading}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
    >
      {logo && logo.length > 0 && (
        <Box
          {...logoProps}
          className={classNames(styles.logo, logoProps?.className)}
        >

          <img
            src={applyJob ? logo : URL.createObjectURL(logo[0])}
            alt={heading}
          />
         
        </Box>
      )}
      {children}
    </Header>
  );
};

HeaderJob.defaultProps = {
  ...headerDefaultProps,
  captionProps: {
    ...headerDefaultProps.captionProps,
  },
  alignContent: "center",
  alignItems: "center",
  containerProps: {
    ...headerDefaultProps.containerProps,
  },
  contextProps: {
    ...headerDefaultProps.contextProps,
    variant: "h2",
  },
  headingProps: {
    ...headerDefaultProps.headingProps,
    variant: "h2",
  },
  isPreview: false,
  logo: null,
  logoProps: null,
  minHeight: 0,
};

HeaderJob.propTypes = {
  ...headerPropTypes,
  isPreview: PropTypes.bool,
  logo: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        attachment: PropTypes.shape({}), // File attachment
        base64: PropTypes.string,
        previewURL: PropTypes.string,
      })
    ),
    PropTypes.shape({
      localFiles: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              base64: PropTypes.string,
              aspectRatio: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
        })
      ),
    }),
    PropTypes.string,
  ]),
  logoProps: PropTypes.shape({
    className: PropTypes.string,
    component: PropTypes.elementType,
    // ...restLogoProps: https://material-ui.com/system/basics/#all-inclusive
  }),
};
