import React from "react";
import { string } from "prop-types";
import {
  Box,
  Container,
  Grid,
  Hidden,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  // TableCell,
  // TableRow,
  Typography,
} from "@material-ui/core";

import {
  Button,
  Card,
  CardCTA,
  CardJob,
  // Code,
  Divider,
  Link,
  List,
  ListItem,
  // Media,
  Paper,
  // Quote,
  Section,
  SectionSplit,
  SectionCTA,
  // Table,
  Text,
  // TextH1,
  // TextH2,
  // TextH3,
  // TextH4,
  // TextH5,
  // TextH6,
} from "components";

// Table of components: https://mdxjs.com/getting-started#table-of-components

// TOOD:
// - add missing shortcodes in order of priority (missing components are commented out).
//   - should create a ticket to track this later.
// - add more material-ui components (low priority - can be done asynchronously)
//   - for now just add them when a need for the component arises
// - add ability to create forms in markdown (low priority - we only have one form right now)
//   - should be able to pull forms into markdown by using the form container
//      - need to create contact form or other forms besides post new job
//      - form container should be able to build forms based on config in form.yml
//   - should also have access to low level form components to create custom forms
//      - need to add mui-rff and custom form components

// components - configure which components are available for use in mdx files.
// (so there's no need to import commonly used components inside the markdown file every time).
export const components = {
  a: Link,
  // blockquote: Quote,
  // code: Code,
  delete: (props) => (
    <Text className="delete" variant="overline" component="span" {...props} />
  ),
  // h1: TextH1,
  h1: (props) => <Text variant="h2" component="h1" {...props} />,
  // h2: TextH2,
  h2: (props) => <Text variant="h3" component="h2" {...props} />,
  // h3: TextH3,
  h3: (props) => <Text variant="h4" component="h3" {...props} />,
  // h4: TextH4,
  h4: (props) => <Text variant="h5" component="h4" {...props} />,
  // h5: TextH5,
  h5: (props) => <Text variant="h6" component="h5" {...props} />,
  // h6: TextH6,
  h6: (props) => <Text variant="subtitle1" component="h6" {...props} />,
  hr: Divider,
  // img: Media,
  // inlineCode: (props) => <Code variant="inline" {...props} />,
  li: (props) => (
    <ListItem>
      <ListItemText {...props} />
    </ListItem>
  ),
  ol: (props) => <List component="ol" {...props} />,
  p: Text,
  // table: Table,
  // td/th: TableCell,
  // tr: TableRow,
  // pre: (props) => <Code variant="pre" {...props} />,
  thematicBreak: (props) => <Divider className="thematic" {...props} />,
  ul: List,
  Box,
  Button,
  Card,
  CardCTA,
  CardJob,
  Container,
  Divider,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Section,
  SectionSplit,
  SectionCTA,
  Text,
  Typography,
};

export const mdxDefaultProps = {
  body: null,
  component: "article",
  disableGutters: false,
  maxWidth: "md",
};

export const mdxPropTypes = {
  body: string,
  // ...restcontainerProps: https://material-ui.com/api/container/#props
};
