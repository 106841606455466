import React, { useState } from "react";
import classNames from "classnames";
import { GatsbySeo as SEO, JsonLd } from "gatsby-plugin-next-seo";
import { Box } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby";

import { LayoutDrawer, LayoutFooter, LayoutHeader } from "./components";
import { useLayoutStyles } from "./Layout.styles";
import { layoutDefaultProps, layoutPropTypes } from "./config";

export const Layout = ({
  children,
  drawerProps,
  footerProps,
  headerProps,
  jsonLd,
  mainProps,
  seoProps,
}) => {
  const styles = useLayoutStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  if (!children) return null;

  return (
    <StaticQuery
      query={graphql`
        query SiteMetadataQuery {
          site {
            siteMetadata {
              copyright
              memorial
              updated
              name
              organization {
                name
                url
              }
              socialMedia {
                instagram
                twitter
              }
              stack
              subscribe
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            copyright,
            memorial,
            updated,
            name,
            organization,
            socialMedia,
            stack,
            subscribe,
          },
        },
      }) => (
        <>
          <SEO
            {...seoProps}
            titleTemplate={seoProps?.title ? `%s | ${name}` : `${name} | %s`}
          />
          {jsonLd && <JsonLd defer={false} json={jsonLd} />}
          <Box className={styles.layout}>
            <LayoutHeader
              onToggleDrawer={handleToggleDrawer}
              {...headerProps}
            />
            <Box
              {...mainProps}
              className={classNames(styles.main, mainProps.className)}
            >
              {children}
            </Box>
            <LayoutFooter
              copyright={copyright}
              memorial={memorial}
              updated={updated}
              name={name}
              organization={organization}
              socialMedia={socialMedia}
              stack={stack}
              subscribe={subscribe}
              {...footerProps}
            />
            <LayoutDrawer
              onClose={handleToggleDrawer}
              open={isDrawerOpen}
              subscribe={subscribe}
              {...drawerProps}
            />
          </Box>
        </>
      )}
    />
  );
};

Layout.defaultProps = layoutDefaultProps;
Layout.propTypes = layoutPropTypes;
