import { makeStyles } from "@material-ui/core/styles";

export const useSwipeStyles = makeStyles(({ spacing, transitions }) => ({
  swipe: {
    display: "flex",
    flexFlow: "row nowrap",
    flex: 1,
    "&.still": {
      transform: "translateX(0%)",
      transition: transitions.create(["transform"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeIn,
      }),
    },
    "&.swiping": {
      transition: "none",
      "&.next": {
        transform: "translateX(calc(-25% - 16px))",
      },
      "&.prev": {
        transform: "translateX(calc(2 * (-13.5% - 16px)))",
      },
    },
  },
  swipeable: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: spacing(0, 1),
    whiteSpace: "nowrap",
  },
  wrapper: {
    flex: 1,
    overflow: "hidden",
    position: "relative",
  },
}));
