import React from "react";
import classNames from "classnames";
import { Disqus } from "gatsby-plugin-disqus";
import { Container } from "@material-ui/core";

import { Article, CardNext, HeaderPost, Section, Text } from "components";
import { useOldestPostQuery } from "graphql";

import { useBlogPostStyles } from "./BlogPost.styles";

export const BlogPost = ({
  article,
  author,
  category,
  className,
  date,
  description,
  id,
  path,
  pageContext,
  showComments,
  title,
  updateDate,
  ...rest
}) => {
  const styles = useBlogPostStyles();
  const oldestPost = useOldestPostQuery();
  const showNext = pageContext?.next !== null || oldestPost.id !== id;

  const disqusConfig = {
    identifier: id,
    title: title,
    url: path,
  };

  const containerProps = {
    disableGutters: true,
    maxWidth: "md",
    ...rest,
  };

  const headerProps = {
    author,
    category,
    date,
    disqusConfig,
    showComments,
    title,
  };

  return (
    <Container
      {...containerProps}
      className={classNames(styles.container, className)}
    >
      <HeaderPost {...headerProps} />
      {article?.childMdx && (
        <Section className="compact" marginBottom={6} marginTop={2}>
          <Article body={article.childMdx.body} />
          {showComments && (
            <Disqus className={styles.comments} config={disqusConfig} />
          )}
        </Section>
      )}
      {showNext && (
        <Section className={classNames(styles.next, "compact")}>
          <Text variant="h3">Next Up</Text>
          {pageContext.next ? (
            <CardNext
              className={styles.card}
              path={`/blog/${pageContext.next.data?.id}`}
              title={pageContext.next.data?.title}
            />
          ) : (
            <CardNext
              className={styles.card}
              path={`/blog/${oldestPost.id}`}
              title={oldestPost.title}
            />
          )}
        </Section>
      )}
    </Container>
  );
};
