import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box } from "@material-ui/core";

import { MDX, headerPropTypes } from "components";

import { useArticleStyles } from "./Article.styles";

export const Article = ({ body, boxProps, className, isPreview }) => {
  const styles = useArticleStyles();

  if (!body) return null;

  if (isPreview) {
    return (
      <Box
        className={classNames(styles.article, className)}
        dangerouslySetInnerHTML={{ __html: body }}
        {...boxProps}
      />
    );
  }

  const mdxProps = {
    body,
    disableGutters: true,
    maxWidth: false,
  };

  return (
    <Box className={classNames(styles.article, className)} {...boxProps}>
      <MDX {...mdxProps} />
    </Box>
  );
};

Article.defaultProps = {
  className: null,
  header: null,
  isPreview: false,
};

Article.propTypes = {
  boxProps: PropTypes.shape({
    component: PropTypes.string,
  }), // boxProps: https://material-ui.com/system/basics/#all-inclusive
  className: PropTypes.string,
  body: PropTypes.string.isRequired,
  header: PropTypes.shape({
    ...headerPropTypes,
  }),
  isPreview: PropTypes.bool,
};
