import React from "react";
import classNames from "classnames";
import { Box, Typography, Grid } from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import {
  Button,
  Description,
  Divider,
  HeaderJob,
  Section,
  SectionSplit,
} from "components";
import { getUrl } from "utils/form";

import { useJobPostStyles } from "./JobPost.styles";
import { jobPostDefaultProps, jobPostPropTypes } from "./config";

// TODO:
// - create and use GridMedia component to display companyImages

export const JobPost = ({
  applyButtonLabel,
  applyJob,
  className,
  companyDescription,
  companyImages,
  companyLogo,
  companyMission,
  companyName,
  companyUrl,
  hiringDescription,
  hiringManager,
  hiringStartDate,
  jobApplication,
  jobCategory,
  jobDescription,
  jobRegions,
  jobTitle,
  jobType,
  isPreview,
  websiteLinkLabel,
  ...restCompositionJobProps
}) => {
  const styles = useJobPostStyles();
  const showAbout = companyMission || companyImages;
  const showHiringProcess =
    hiringDescription || hiringManager || hiringStartDate;

  const headerProps = {
    context: `@ ${companyName}`,
    heading: jobTitle,
    isPreview,
    applyJob,
    logo: companyLogo,
  };

  const isLastObject = {
    jobDescription: Boolean(jobDescription),
    showAbout: Boolean(showAbout),
    companyDescription: Boolean(companyDescription),
    companyUrl: Boolean(companyUrl),
    showHiringProcess: Boolean(showHiringProcess),
  };

  const renderDivider = (isLastObject, sectionID, isPreview) => {
    // set the current sectionID in isLastObject to false
    isLastObject[sectionID] = false;

    // iterate over isLastObject values to see if they are all false
    let falseCheck = false;
    Object.values(isLastObject).forEach((val) => {
      falseCheck = falseCheck || val;
    });

    if (falseCheck) {
      // if true then at least one more divider occurs after this one
      return <Divider className={styles.divider} />;
    } else {
      if (isPreview) {
        // if there are no other dividers, and it's a preview then draw secondary divider
        return <Divider className={classNames(styles.divider, "secondary")} />;
      }
      // if it's not a preview and it's the last one, then no divider
      return;
    }
  };

  return (
    <Box
      {...restCompositionJobProps}
      className={classNames(styles.box, className, { preview: isPreview })}
    >
      <HeaderJob {...headerProps} />
      <Section className="compact" marginBottom={6} marginTop={2}>
        <Typography className={styles.meta} component="h2" variant="body1">
          {jobCategory}
        </Typography>
        <Typography className={styles.meta} component="h2" variant="body1">
          {jobType}
        </Typography>
        <Typography className={styles.meta} component="h2" variant="body1">
          {jobRegions.join(", ")}
        </Typography>
      </Section>
      <SectionSplit
        className="compact"
        left={
          <Typography className={styles.heading} component="h2" variant="h3">
            Job Description
          </Typography>
        }
        right={
          <>
            <Description body={jobDescription} isPreview={isPreview} />
            <Button
              className={styles.button}
              color="primary"
              component={OutboundLink}
              disabled={isPreview}
              href={getUrl(jobApplication)}
              size="large"
              target="_blank"
            >
              {applyButtonLabel}
            </Button>
            {renderDivider(isLastObject, "jobDescription", isPreview)}
          </>
        }
      />
      {showAbout && (
        <SectionSplit
          className="compact"
          left={
            <Typography className={styles.heading} component="h2" variant="h3">
              About {companyName}
            </Typography>
          }
          right={
            <>
              {companyMission && <Typography>{companyMission}</Typography>}
              {companyImages && (
                <Grid container direction="row" spacing={3}>
                  {companyImages.map((img, index) => {
                    return (
                      <Grid
                        className={styles.imageItem}
                        item
                        sm={2}
                        md={3}
                        xl={4}
                        key={index}
                      >
                        <img
                          className={styles.image}
                          src={applyJob ? img : URL.createObjectURL(img)}
                          alt={`company-images-${index}`}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              {renderDivider(isLastObject, "showAbout", isPreview)}
            </>
          }
        />
      )}
      {companyUrl && (
        <SectionSplit
          className="compact"
          left={
            <Typography className={styles.heading} component="h2" variant="h3">
              Website
            </Typography>
          }
          right={
            <>
              <Typography
                className={styles.link}
                component={OutboundLink}
                href={getUrl(companyUrl)}
                target="_blank"
              >
                {companyUrl}
              </Typography>
              {renderDivider(isLastObject, "companyUrl", isPreview)}
            </>
          }
        />
      )}
      {companyDescription && (
        <SectionSplit
          className="compact"
          left={
            <Typography className={styles.heading} component="h2" variant="h3">
              Values
            </Typography>
          }
          right={
            <>
              <Description body={companyDescription} isPreview={isPreview} />
              {renderDivider(isLastObject, "companyDescription", isPreview)}
            </>
          }
        />
      )}
      {showHiringProcess && (
        <SectionSplit
          className="compact"
          left={
            <Typography className={styles.heading} component="h2" variant="h3">
              Hiring process
            </Typography>
          }
          right={
            <>
              {hiringManager && (
                <Typography>
                  <strong>Hiring Manager:</strong> {hiringManager}
                </Typography>
              )}
              {hiringStartDate && (
                <Typography>
                  <strong>Anticipated start date:</strong> {hiringStartDate}
                </Typography>
              )}
              {hiringDescription && (
                <>
                  <Typography>
                    <strong>Interview process:</strong>
                  </Typography>
                  <Description body={hiringDescription} isPreview={isPreview} />
                </>
              )}
              {renderDivider(isLastObject, "showHiringProcess", isPreview)}
            </>
          }
        />
      )}
      <SectionSplit
        left={<></>}
        right={
          <Button
            className={styles.button}
            color="primary"
            component={OutboundLink}
            disabled={isPreview}
            href={getUrl(jobApplication)}
            size="large"
            target="_blank"
          >
            {applyButtonLabel}
          </Button>
        }
      />
    </Box>
  );
};

JobPost.defaultProps = jobPostDefaultProps;
JobPost.propTypes = jobPostPropTypes;
