import React from "react";
import classNames from "classnames";
import { List as MuiList } from "@material-ui/core";

import { useListStyles } from "./List.styles";
import { listDefaultProps, listPropTypes } from "./config";

export const List = ({ children, className, restListProps }) => {
  const styles = useListStyles();

  return (
    <MuiList {...restListProps} className={classNames(styles.list, className)}>
      {children}
    </MuiList>
  );
};

List.defaultProps = listDefaultProps;
List.propTypes = listPropTypes;
