import { useStaticQuery, graphql } from "gatsby";

export const useNewJobFormQuery = () => {
  const {
    form: {
      form: { action, buttons, fields, method, name, steps },
    },
  } = useStaticQuery(
    graphql`
      query NewJobFormQuery {
        form: formsYaml(id: { eq: "FormNewJob" }) {
          form {
            action
            buttons {
              next
              prev
              submit
            }
            fields {
              companyDescription {
                helperText
                label
                name
              }
              companyEmail {
                helperText
                label
                name
                required
                type
              }
              companyImages {
                helperText
                label
                multiple
                name
              }
              companyLogo {
                helperText
                label
                name
              }
              companyMission {
                helperText
                label
                name
              }
              companyName {
                helperText
                label
                name
                required
              }
              companyUrl {
                helperText
                label
                name
                type
              }
              hiringDescription {
                helperText
                label
                name
              }
              hiringManager {
                helperText
                label
                name
              }
              hiringStartDate {
                helperText
                label
                name
              }
              jobApplication {
                helperText
                label
                name
                required
              }
              jobCategory {
                data {
                  label
                  value
                }
                label
                name
                required
              }
              jobDescription {
                helperText
                label
                name
                required
              }
              jobRegions {
                data {
                  label
                  value
                }
                helperText
                label
                multiple
                name
                required
              }
              jobTitle {
                helperText
                label
                name
                required
              }
              jobType {
                data {
                  label
                  value
                }
                label
                name
                required
              }
            }
            method
            name
            steps
          }
          id
        }
        # prices: allStripePrice {
        #   edges {
        #     node {
        #       currency
        #       id
        #       unit_amount_decimal
        #     }
        #   }
        # }
      }
    `
  );

  return {
    action,
    buttons,
    fields,
    method,
    name,
    steps,
  };
};
