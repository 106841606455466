// Validation with yup - https://github.com/jquense/yup
import * as yup from "yup";

export const DESCRIPTION_MIN_LENGTH = 10;

// companyInfoSchema
export const companyInfoSchema = yup.object().shape({
  companyEmail: yup
    .string()
    .email("Please enter a valid email address. ")
    .required("We need an email address to contact you. "),
  companyName: yup
    .string()
    .min(2, "A company name should be at least 2 characters long. ")
    .required("We need to know the name of your company. "),
});

// contactInfoSchema
export const contactInfoSchema = yup.object().shape({
  description: yup
    .string()
    .min(
      DESCRIPTION_MIN_LENGTH,
      "Please write a message of at least 10 characters. "
    )
    .required("Please leave a reason for contacting us. "),
  email: yup
    .string()
    .email("Please enter a valid email address. ")
    .required("We need an email address to contact you. "),
  name: yup
    .string()
    .min(3, "Come on, a name is usually longer than that. ")
    .required("What should we call you?"),
});

// jobInfoSchema
export const jobInfoSchema = yup.object().shape({
  jobApplication: yup
    .string()
    .test(
      "jobApplication",
      "Please enter a proper URL or email address. ",
      (value) => {
        // TODO: refactor this into a separate utility.
        const emailRegex =
          /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        const urlRegex =
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#()?&//=]*)/;
        let emailValid = emailRegex.test(value);
        let urlValid = urlRegex.test(value);

        if (!emailValid && !urlValid) return false;

        return true;
      }
    )
    .required("Please provide an application page URL or email address. "),
  jobCategory: yup
    .string()
    .required("Please select a category that matches the job title. "),
  jobDescription: yup
    .string()
    .test(
      "jobDescription",
      `Please write a job description that's ${DESCRIPTION_MIN_LENGTH} or more characters in length. `,
      (value) => {
        const cleanValue = value.replace(/<(.|\n)*?>/g, "").trim();

        if (cleanValue.length < DESCRIPTION_MIN_LENGTH) return false;

        return true;
      }
    )
    .required("Please describe the job in as much detail as possible. "),
  jobRegions: yup
    .array()
    .min(1, "Please select at least one region from the list. ")
    .required(
      "We need to know which region(s) this job can be performed in a timely manner. "
    ),
  jobTitle: yup
    .string()
    .min(4, "A job title should be at least 4 characters long. ")
    .required("A job title is required to post a job. "),
  jobType: yup.string().required("Please choose a job type for this job. "),
});
