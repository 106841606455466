import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
} from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { Link } from "components";
import { Brand } from "containers";

import { useLayoutDrawerStyles } from "./Drawer.styles";

export const LayoutDrawer = ({
  onClose,
  open,
  subscribe,
  ...restLayoutDrawerProps
}) => {
  const styles = useLayoutDrawerStyles();

  return (
    <Hidden mdUp>
      <Drawer
        classes={{ paper: styles.drawer }}
        open={open}
        onClose={onClose}
        {...restLayoutDrawerProps}
      >
        <Box className={styles.header} component="header">
          <Brand className="secondary" />
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List className={styles.nav} component="nav">
          <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/jobs"
          >
            Find a Job
          </ListItem>
          <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/jobs/new"
          >
            Post a Job
          </ListItem>
          {subscribe && (
            <ListItem
              className={styles.navItem}
              component={OutboundLink}
              button
              href={subscribe}
              target="_blank"
            >
              Subscribe
            </ListItem>
          )}
          <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/about"
          >
            About
          </ListItem>
          <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/resources"
          >
            Resources
          </ListItem>
          <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/blog"
          >
            Blog
          </ListItem>
          {/* <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/terms"
          >
            Terms of Service
          </ListItem>
          <ListItem
            className={styles.navItem}
            component={Link}
            button
            to="/privacy"
          >
            Privacy Policy
          </ListItem> */}
        </List>
      </Drawer>
    </Hidden>
  );
};

LayoutDrawer.defaultProps = {
  anchor: "right",
  subscribe: null,
};

LayoutDrawer.propTypes = {
  anchor: PropTypes.oneOf(["bottom", "left", "right", "top"]),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  subscribe: PropTypes.string,
  // ...restLayoutDrawerProps: https://material-ui.com/api/drawer/#props
};
