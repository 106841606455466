import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  AppBar,
  Box,
  ButtonBase,
  Container,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import { Button, Link } from "components";
import { Brand } from "containers";

import { useLayoutHeaderStyles } from "./Header.styles";

// TODO:
// - define buttonProps defaults and propTypes
// - setup config file for header, use to set defaults in layout

export const LayoutHeader = ({
  brandProps,
  buttonProps,
  className,
  color,
  containerProps,
  elevation,
  isHidden,
  onToggleDrawer,
  position,
  toolbarProps,
}) => {
  const styles = useLayoutHeaderStyles();
  const isSm = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"));

  if (isHidden) return null;

  return (
    <AppBar
      className={className}
      color={color}
      elevation={elevation}
      position={position}
    >
      <Container {...containerProps}>
        <Toolbar
          {...toolbarProps}
          className={classNames(styles.toolbar, toolbarProps.className)}
        >
          <Brand {...brandProps} />
          {isSm ? (
            <Box className={classNames(styles.nav, "right")} component="nav">
              <Button
                className={classNames(
                  styles.button,
                  buttonProps?.className,
                  color === "primary" && "no-shadow"
                )}
                color={
                  buttonProps?.color
                    ? buttonProps.color
                    : color === "primary"
                    ? "primary"
                    : "secondary"
                }
                component={Link}
                size="small"
                to="/jobs/new"
              >
                Post a Job
              </Button>
              <ButtonBase
                className={classNames(
                  styles.menuButton,
                  buttonProps?.color
                    ? buttonProps.color
                    : color === "primary" && color
                )}
                onClick={onToggleDrawer}
              />
            </Box>
          ) : (
            <>
              <Box className={styles.nav} component="nav">
                <Typography
                  activeClassName="active"
                  className={styles.link}
                  component={Link}
                  to="/about"
                >
                  About
                </Typography>
                <Typography
                  activeClassName="active"
                  className={styles.link}
                  component={Link}
                  to="/resources"
                >
                  Resources
                </Typography>
                <Typography
                  activeClassName="active"
                  className={styles.link}
                  component={Link}
                  to="/blog"
                >
                  Blog
                </Typography>
              </Box>
              <Box className={classNames(styles.nav, "right")} component="nav">
                <Typography
                  activeClassName="active"
                  className={styles.link}
                  component={Link}
                  to="/jobs"
                >
                  Jobs
                </Typography>
                <Button
                  className={classNames(
                    styles.button,
                    buttonProps?.className,
                    color === "primary" && "no-shadow"
                  )}
                  color={
                    buttonProps?.color
                      ? buttonProps.color
                      : color === "primary"
                      ? "primary"
                      : "secondary"
                  }
                  component={Link}
                  to="/jobs/new"
                >
                  Post a Job
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

LayoutHeader.defaultProps = {
  brandProps: null,
  className: null,
  color: "inherit",
  containerProps: {
    className: null,
  },
  elevation: 0,
  isHidden: false,
  position: "relative",
  toolbarProps: {
    className: null,
    disableGutters: true,
  },
};

LayoutHeader.propTypes = {
  brandProps: PropTypes.shape({
    className: PropTypes.string,
  }), // brandProps: `/containers/brand`
  className: PropTypes.string,
  color: PropTypes.string,
  containerProps: PropTypes.shape({
    className: PropTypes.string,
  }), // containerProps: https://material-ui.com/api/container/#props
  elevation: PropTypes.number,
  isHidden: PropTypes.bool,
  onToggleDrawer: PropTypes.func.isRequired,
  position: PropTypes.string,
  toolbarProps: PropTypes.shape({
    className: PropTypes.string,
    disableGutters: PropTypes.bool,
  }), // toolbarProps: https://material-ui.com/api/toolbar/#props
};
