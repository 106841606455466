import { makeStyles } from "@material-ui/core/styles";

export const useHeaderFilterStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    container: {
      alignItems: "center",
      display: "flex",
      [breakpoints.up("md")]: {
        alignItems: "center",
        flexFlow: "column nowrap",
      },
    },
    header: {
      padding: spacing(4, 0, 0),
      [breakpoints.up("md")]: {
        paddingTop: spacing(8),
      },
    },
    heading: {
      color: palette.text.primary,
      flex: "none",
      fontWeight: typography.fontWeightExtraBold,
      margin: 0,
      marginRight: spacing(2),
    },
  })
);
