import React, { forwardRef, useRef } from "react";
import classNames from "classnames";
import { FormControl, InputLabel } from "@material-ui/core";

import { FieldHelperText } from "./helper-text/FieldHelperText";
import { useFormFieldStyles } from "./FormField.styles";

import { defaultProps, propTypes } from "../config";
import { useFieldState, showError } from "../utils";

// TODO:
// - toggle error state and helper text here using error utility component
// - reference: https://github.com/lookfirst/mui-rff/blob/master/src/Util.tsx
// - Field wrapper around a component rendered through props

export const FormField = forwardRef(
  (
    {
      children,
      disabled,
      className,
      formControlProps,
      formHelperTextProps,
      helperText,
      inputLabelProps,
      label,
      name,
      required,
      variant,
      focused,
    },
    ref
  ) => {
    const styles = useFormFieldStyles();
    const inputLabel = useRef(ref || null);
    const field = useFieldState(name);
    const isError = showError(field);

    return (
      <FormControl
        className={classNames(styles.field, className)}
        disabled={disabled}
        error={isError}
        focused={focused}
        required={required}
        variant={variant}
        {...formControlProps}
      >
        {!!label && (
          <InputLabel htmlFor={name} ref={inputLabel} {...inputLabelProps}>
            {label}
          </InputLabel>
        )}
        {children}
        {!!helperText && (
          <FieldHelperText helperText={helperText} {...formHelperTextProps} />
        )}
      </FormControl>
    );
  }
);

FormField.defaultProps = defaultProps;
FormField.propTypes = propTypes;
