import { useStaticQuery, graphql } from "gatsby";

export const useOldestPostQuery = () => {
  const {
    posts: { nodes },
  } = useStaticQuery(graphql`
    query OldestPostQuery {
      posts: allAirtable(
        sort: { order: ASC, fields: data___date }
        limit: 1
        filter: { table: { eq: "Posts" } }
      ) {
        nodes {
          data {
            title
            id
          }
        }
      }
    }
  `);

  return nodes[0]?.data;
};
