import { makeStyles } from "@material-ui/core/styles";

export const useJobPostStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    box: {
      marginBottom: spacing(8),
      "&.preview": {
        marginBottom: 0,
      },
    },
    button: {
      marginTop: spacing(1),
      width: "100%",
      [breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    divider: {
      margin: spacing(6, "auto", 4.5),
    },
    heading: {
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1,
    },
    imageItem: {
      overflow: "hidden",
    },
    image: {
      height: "auto",
      width: "100%",
    },
    link: {
      color: palette.text.secondary,
      fontWeight: typography.fontWeightMedium,
      "&:hover": {
        color: palette.text.secondary,
      },
    },
    meta: {
      display: "inline-block",
      marginRight: spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
    },
    subheading: {
      fontWeight: typography.fontWeightMedium,
      lineHeight: 1.5,
      marginBottom: spacing(2),
    },
  })
);
