import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as GatsbyLink } from "gatsby";
import { Link as MuiLink } from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { useLinkStyles } from "./Link.styles";

export const Link = forwardRef(({ className, href, ...restLinkProps }, ref) => {
  const styles = useLinkStyles();

  if (href) {
    return (
      <MuiLink
        ref={ref}
        {...restLinkProps}
        className={classNames(styles.link, className)}
        component={OutboundLink}
        href={href}
      />
    );
  }

  return (
    <MuiLink
      ref={ref}
      {...restLinkProps}
      className={classNames(styles.link, className)}
    />
  );
});

Link.defaultProps = {
  color: "inherit",
  component: GatsbyLink,
  href: null,
  underline: "always",
};

Link.propTypes = {
  color: PropTypes.oneOf([
    "initial",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "error",
  ]),
  component: PropTypes.elementType,
  href: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  to: PropTypes.string,
  underline: PropTypes.oneOf(["none", "hover", "always"]),
}; // restLinkProps: https://material-ui.com/api/link/#props
