import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { Box, Container, Typography } from "@material-ui/core";

import { Button, Link } from "components";

import { useHeaderStyles } from "./Header.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

// TODO:
// - render different buttons depending on button props for buttons
//   - create button component with different buttonTypes (link, outgoing-link, etc...)
// - look for other ways to check if component should return null (isEmpty)

export const Header = ({
  alignContent,
  alignItems,
  bgcolor,
  buttonProps,
  buttons,
  buttonsFooter,
  captionProps,
  children,
  className,
  color,
  component,
  content,
  image,
  justifyContent,
  minHeight,
  buttonsProps,
  context,
  contextProps,
  containerProps,
  heading,
  headingProps,
  ...restHeaderProps
}) => {
  const styles = useHeaderStyles();
  const isEmpty = !buttons && !children && !context && !heading && !image;

  if (isEmpty) return null;

  return (
    <Box
      alignContent={alignContent}
      alignItems={alignItems}
      bgcolor={bgcolor}
      className={classNames(styles.header, className)}
      color={color}
      component={component}
      justifyContent={justifyContent}
      minHeight={minHeight}
      {...restHeaderProps}
    >
      {image && image.childImageSharp && (
        <Img
          alt={image.name || heading}
          className={classNames(styles.image, image.className)}
          {...image.childImageSharp}
        />
      )}
      <Container
        {...containerProps}
        className={classNames(styles.container, containerProps.className)}
      >
        <Box
          {...captionProps}
          className={classNames(styles.caption, captionProps.className)}
        >
          {heading && (
            <Typography
              {...headerDefaultProps.headingProps}
              {...headingProps}
              className={classNames(styles.heading, headingProps?.className)}
            >
              {heading}
            </Typography>
          )}
          {context && (
            <Typography
              {...headerDefaultProps.contextProps}
              {...contextProps}
              className={classNames(styles.context, contextProps.className)}
            >
              {context}
            </Typography>
          )}
          {content}
        </Box>
        {children}
        {buttons && buttons.length > 0 && (
          <Box
            {...buttonsProps}
            className={classNames(styles.buttons, buttonsProps.className)}
          >
            {buttons.map((button) => (
              <Button
                activeClassName="active"
                component={Link}
                to={button.path}
                {...buttonProps}
                {...button}
                className={classNames(
                  styles.button,
                  buttonProps.className,
                  button.className
                )}
                color={button.color || buttonProps.color}
                key={button.id || button.label}
                size={button.size || buttonProps.size}
                variant={button.variant || buttonProps.variant}
              >
                {button.label}
              </Button>
            ))}
            {buttonsFooter}
          </Box>
        )}
      </Container>
    </Box>
  );
};

Header.defaultProps = headerDefaultProps;
Header.propTypes = headerPropTypes;
