import React from "react";
import classNames from "classnames";
import { Grid, useMediaQuery } from "@material-ui/core";

import { CardResource, Filter, Link, Section, Text } from "components";
import { SubscribeCard } from "containers";

import { useSectionResourcesStyles } from "./SectionResources.styles";
import {
  sectionResourcesDefaultProps,
  sectionResourcesPropTypes,
} from "./config";

export const SectionResources = ({
  children,
  className,
  filters,
  heading,
  headingProps,
  link,
  linkProps,
  location,
  resourceProps,
  resources,
  resourcesProps,
  ...restSectionProps
}) => {
  const styles = useSectionResourcesStyles();
  const isSm = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"));
  const hasResources = resources?.edges?.length > 0;

  if (!hasResources) return null;

  return (
    <Section
      {...restSectionProps}
      className={classNames(styles.section, className)}
    >
      {children}
      {isSm && <SubscribeCard />}
      <Grid
        container
        {...sectionResourcesDefaultProps?.resourcesProps}
        {...resourcesProps}
        className={classNames(styles.resources, resourcesProps?.className)}
      >
        {filters && (
          <Grid className={styles.filter} item xs={12} md={2}>
            <Filter
              buttonClassName={styles.button}
              disableRipple
              filters={filters}
              location={location}
              orientation="vertical"
              variant="text"
            />
          </Grid>
        )}
        <Grid className={styles.main} item xs={12} md={10}>
          {!isSm && <SubscribeCard />}
          <Grid
            container
            {...sectionResourcesDefaultProps?.resourcesProps}
            className={classNames(
              styles.resources,
              sectionResourcesDefaultProps?.resourcesProps?.className
            )}
          >
            {resources.edges.map(({ node: { data, id } }) => (
              <Grid
                item
                key={id}
                {...sectionResourcesDefaultProps?.resourceProps}
                {...resourceProps}
                className={classNames(styles.resource)}
              >
                <CardResource {...data} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {link && (
        <>
          <Text
            component={Link}
            to={link.path}
            {...sectionResourcesDefaultProps?.linkProps}
            {...linkProps}
            className={classNames(styles.link, linkProps?.className)}
          >
            {link.label}
          </Text>
        </>
      )}
    </Section>
  );
};

SectionResources.defaultProps = sectionResourcesDefaultProps;
SectionResources.propTypes = sectionResourcesPropTypes;
