import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { StaticQuery, graphql } from "gatsby";

import { Button, Card, Link, Text } from "components";

import { useSubscribeCardStyles } from "./SubscribeCard.styles";

export const SubscribeCard = ({
  buttonLabel = "Subscribe",
  className = "cta",
  heading = "Subscribe for remote jobs!",
  content,
  ...restSubscribeCardProps
}) => {
  const styles = useSubscribeCardStyles();

  return (
    <StaticQuery
      query={graphql`
        query SubscribeCardQuery {
          mascot: file(name: { eq: "mascot-subscribe" }) {
            childImageSharp {
              fluid(
                maxWidth: 600
                quality: 90
                traceSVG: { color: "#474d60" }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
            name
          }
          site {
            siteMetadata {
              subscribe
            }
          }
        }
      `}
      render={({
        mascot,
        site: {
          siteMetadata: { subscribe },
        },
      }) => {
        const subscribeCardProps = {
          className: classNames(styles.card, className),
          heading,
          headingProps: {
            className: styles.heading,
            component: "h4",
            variant: "h4",
          },
          content: content ? (
            content
          ) : (
            <>
              <Text className={styles.cardText}>
                Get the latest remote jobs directly to your inbox!
              </Text>
              <Text className={styles.cardText}>
                No spam, ever. Your email's safe with us.
              </Text>
              {subscribe && (
                <Button
                  className={styles.cardButton}
                  color="primary"
                  component={Link}
                  to={subscribe}
                  variant="contained"
                >
                  {buttonLabel}
                </Button>
              )}
            </>
          ),
          contentProps: { className: styles.cardContent },
          ...restSubscribeCardProps,
        };

        return (
          <Card {...subscribeCardProps}>
            {mascot?.childImageSharp && (
              <Img
                alt={mascot.name || heading}
                className={classNames(styles.mascot, mascot.className)}
                {...mascot.childImageSharp}
              />
            )}
          </Card>
        );
      }}
    />
  );
};
