import { makeStyles } from "@material-ui/core/styles";

export const useSectionCTAStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    card: {
      borderColor: palette.grey[600],
      border: "2px solid",
      marginBottom: spacing(4),
      minHeight: 250,
      [breakpoints.up("sm")]: {
        marginBottom: spacing(8),
      },
      "& .MuiCardContent-root": {
        alignContent: "flex-start",
        alignItems: "flex-start",
        flexFlow: "column nowrap",
        justifyContent: "center",
        [breakpoints.up("md")]: {
          alignItems: "flex-start",
        },
      },
    },
    image: {
      bottom: spacing(-12),
      transform: "scale(1.5)",
      zIndex: -1,
      [breakpoints.up("sm")]: {
        bottom: spacing(-8),
        transform: "scale(1.25)",
      },
      [breakpoints.up("md")]: {
        left: spacing(-6),
        transform: "scale(1.5)",
      },
    },
    section: {
      overflow: "hidden",
    },
  })
);
