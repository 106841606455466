import { makeStyles } from "@material-ui/core/styles";

export const useDescriptionStyles = makeStyles(
  ({ palette, shadows, shape, spacing, typography }) => ({
    article: {
      margin: spacing(0, "auto"),
      maxWidth: 800,
      overflowWrap: "break-word",
      "& blockquote, & ol, & ul": {
        margin: spacing(0, 0, 4),
      },
      "& blockquote": {
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        boxShadow: shadows[1],
        padding: spacing(2, 4),
      },
      "& h1, & h2, & h3, & h4": {
        margin: spacing(4, 0),
      },
      "& h1": {
        ...typography.h2,
      },
      "& h2": {
        ...typography.h3,
      },
      "& h3": {
        ...typography.h4,
        lineHeight: 1,
      },
      "& h4": {
        ...typography.h5,
      },
      "& h5, & h6, & p": {
        margin: spacing(0, 0, 2),
      },
      "& h5": {
        ...typography.h6,
      },
      "& h6": {
        ...typography.subtitle1,
      },
      "& p, & li": {
        ...typography.body1,
      },
      "& li": {
        marginBottom: 0,
      },
      "& ol, & ul": {
        paddingLeft: spacing(2.5),
      },
    },
  })
);
