// getPageCategory - get category for current page using location pathname.
export const getPageCategory = (pathname) =>
  pathname
    .match(/\/([a-z0-9_-]*[/]?)$/g)
    .toString()
    .split("/")
    .join("");

// getPageConfig - get config for current page using location pathname.
export const getPageConfig = (config, pageCategory) =>
  config.edges
    .filter((category) => category.node.id.toLowerCase().includes(pageCategory))
    .reduce((object, item) => (object[item] = item.node), {});

// getPageFilters - get page filters using provided categories group.
export const getPageFilters = (
  categories,
  pathPrefix = "/",
  allButton = {
    id: "all",
    label: "All",
  }
) => {
  const categoryButtons = categories.group.map(({ category }) => ({
    id: category.toLowerCase(),
    label: category,
    path: `${pathPrefix}/${category.split(" ").join("-").toLowerCase()}`,
  }));

  const categoryFilters = [
    {
      path: pathPrefix,
      ...allButton,
    },
    ...categoryButtons,
  ];

  return categoryFilters;
};

// getPageMascot - get page masctot by category.
export const getPageMascot = (mascots, pageCategory) => {
  const pageMascot = mascots.edges.find(({ node }) =>
    node.name.includes(pageCategory)
  );

  return pageMascot.node;
};
