import React, { Children, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Container, Grid } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Debug } from "mui-rff";

import { loadStripe } from "@stripe/stripe-js";
import TurndownService from "turndown";

import { Button, Stepper, useFormStyles } from "components";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
const turndownService = new TurndownService();
const PREVIEW_STEP = 3;
const SUCCESS = 200;
const BUCKET_URL = "https://pulp-files.s3.us-east-2.amazonaws.com/";
const PREFIX = new Date().valueOf();
let errorMessage = "";
let airtable_Id = "";

export const FormWizard = ({
  buttons,
  children,
  className,
  footerProps,
  initialValues,
  name,
  noValidate,
  // onSubmit,
  showDebug,
  steps,
  subscription,
  validate,
  action,
  method,
  logoImages,
  compImages,
  id,
}) => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState(initialValues);
  const [submitAction, setSubmit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const styles = useFormStyles();

  const handleClose = () => setOpen(false);

  let activeStep = Children.toArray(children)[step];
  let isLastStep = step === Children.count(children) - 1;
  let activeButtons = activeStep.props.buttons;
  let restFooterProps = {
    ...footerProps,
    ...activeStep.props.footerProps,
  };

  const body = JSON.stringify([
    {
      price: id,
      quantity: 1,
    },
  ]);

  const companyLogoImageUrl = logoImages?.length
    ? `${BUCKET_URL}${PREFIX}_${logoImages[0].name}`
    : "";
  let companyImageUrls = [];
  const imageFiles = [...logoImages, ...compImages];

  compImages && compImages.length
    ? compImages.forEach((file) => {
        companyImageUrls.push(`${BUCKET_URL}${PREFIX}_${file.name}`);
      })
    : (companyImageUrls = "");
  const data = {
    ...values,
    companyDescription: !!values.companyDescription
      ? turndownService.turndown(values.companyDescription)
      : "",
    companyLogoUrl: companyLogoImageUrl,
    companyImages: JSON.stringify(companyImageUrls),
    hiringDescription: !!values.hiringDescription
      ? turndownService.turndown(values.hiringDescription)
      : "",
    jobDescription: turndownService.turndown(values.jobDescription),
  };

  const onNext = (values) => {
    const nextStep = Math.min(step + 1, children.length - 1);
    window.scrollTo(0, 0);
    setStep(nextStep);
    setValues(values);
    setSubmit(false);
  };

  const onPrev = () => {
    const prevStep = Math.max(step - 1, 0);
    window.scrollTo(0, 0);
    setStep(prevStep);
    setSubmit(false);
  };

  const stripeCheckOut = () => {
    fetch("/.netlify/functions/stripe", { body, method })
      .then(async (response) => {
        const { id } = await response.json();
        const stripe = await stripePromise;

        updateAirtable();

        await stripe.redirectToCheckout({
          sessionId: id,
        });
      })
      .catch((error) => {
        const body = JSON.stringify(data);

        fetch(action, { method, body });
        console.warn("stripe error", error);
      });
  };

  const updateAirtable = () => {
    const body = JSON.stringify({
      id: airtable_Id,
    });

    fetch("/.netlify/functions/update", { body, method })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log("airtable error", error);
      });
  };

  const uploadImageToBucket = (images) => {
    try {
      images.forEach((img) => {
        fetch("/.netlify/functions/upload", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: `${PREFIX}_${img.name}`,
            type: img.type,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            const uploadUrl = json.uploadURL;

            return fetch(uploadUrl, {
              method: "PUT",
              headers: { "Content-Type": img.type },
              body: img,
            });
          })
          .catch((error) => {
            throw error;
          });
      });
    } catch (err) {
      console.log("image upload error", err);
    } finally {
      uploadAirtable();
    }
  };

  const uploadAirtable = () => {
    const body = JSON.stringify({
      status: {
        id: airtable_Id,
        isUpdated: update,
      },
      data: { ...data },
    });
    fetch(action, { body, method })
      .then((response) => {
        if (response.status === SUCCESS) {
          onNext(values);
          setUpdate(true);
        } else {
          errorMessage = "Uploadiing is failed";
          setOpen(true);
          setSubmit(false);
        }
        return response.json();
      })
      .then((data) => {
        airtable_Id = data.id;
      })
      .catch((error) => {
        errorMessage = error;
        setOpen(true);
        setSubmit(false);
        console.log("airtable error", error);
      });
  };

  const handleSubmit = (values) => {
    if (step === PREVIEW_STEP) {
      setSubmit(true);
      imageFiles.length ? uploadImageToBucket(imageFiles) : uploadAirtable();
    } else if (isLastStep) {
      setSubmit(true);
      return stripeCheckOut();
    } else onNext(values);
  };

  const handleValidate = (step) => {
    if (validate && validate[step]) return validate[step];

    return false;
  };

  return (
    <>
      <Snackbar open={open} onClose={handleClose} message="I love snacks">
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
      <Stepper activeStep={step} steps={steps} />
      <Form
        initialValues={values}
        onSubmit={handleSubmit}
        subscription={subscription}
        validate={handleValidate(step)}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form
              className={className}
              name={name}
              noValidate={noValidate}
              onSubmit={handleSubmit}
            >
              {activeStep}
              <Container {...restFooterProps}>
                <Grid className={styles.buttons} container>
                  <Grid className={styles.buttonsItem} item xs={12} sm={6}>
                    {step > 0 && (
                      <Button
                        className={styles.button}
                        size="large"
                        type="button"
                        variant="text"
                        onClick={onPrev}
                      >
                        {(activeButtons && activeButtons.prev) || buttons.prev}
                      </Button>
                    )}
                  </Grid>
                  <Grid className={styles.buttonsItem} item xs={12} sm={6}>
                    <Button
                      className={styles.button}
                      color="primary"
                      disabled={
                        submitAction && step >= PREVIEW_STEP ? true : false
                      }
                      size="large"
                      type="submit"
                    >
                      {isLastStep
                        ? (activeButtons && activeButtons.submit) ||
                          buttons.submit
                        : (activeButtons && activeButtons.next) || buttons.next}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              {showDebug && <Debug className={styles.debug} />}
            </form>
          );
        }}
      </Form>
    </>
  );
};

FormWizard.defaultProps = {
  buttons: {
    next: "Next",
    prev: "Back",
    submit: "Submit",
  },
  className: null,
  footerProps: {
    component: "footer",
  },
  initialValues: {},
  name: "form-wizard",
  noValidate: true,
  showDebug: false,
  steps: null,
  subscription: false,
  validate: null,
};

FormWizard.propTypes = {
  buttons: PropTypes.shape({
    next: PropTypes.string,
    prev: PropTypes.string,
    submit: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footerProps: PropTypes.shape({
    component: PropTypes.elementType,
    disableGutters: PropTypes.bool,
    fixed: PropTypes.bool,
    maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  }), // footerProps: https://material-ui.com/api/container/#props
  initialValues: PropTypes.object,
  action: PropTypes.string,
  name: PropTypes.string,
  noValidate: PropTypes.bool,
  showDebug: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.string),
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.arrayOf(PropTypes.func),
};
