import { makeStyles } from "@material-ui/core/styles";

export const useButtonStyles = makeStyles(
  ({ palette, spacing, transitions, typography, shape }) => ({
    button: {
      borderRadius: shape.borderRadiusSubtle,
      fontWeight: typography.fontWeightExtraBold,
      fontFamily: typography.fontBody,
      textTransform: "initial",
      transition: transitions.create(
        ["background-color, border-color, color"],
        {
          duration: transitions.duration.shortest,
          easing: transitions.easing.easeInOut,
        }
      ),
      "&.MuiButton-colorInherit": {
        color: "inherit !important",
      },
      "&.MuiButton-contained": {
        border: "2px solid",
        boxShadow: "-9px -9px 16px #fff, 9px 9px 16px rgba(196, 196, 196, .5)",
        padding: spacing(0.75, 2.5),
        textDecoration: "none",
        "&:hover, &:active": {
          boxShadow: "none",
          textDecoration: "none",
        },
      },
      "&.MuiButton-containedPrimary": {
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.contrastText,
        color: palette.primary.contrastText,
        "&:hover, &:active": {
          backgroundColor: palette.primary.contrastText,
          color: palette.secondary.contrastText,
        },
      },
      "&.MuiButton-containedSecondary": {
        backgroundColor: palette.secondary.contrastText,
        borderColor: palette.primary.contrastText,
        color: palette.primary.contrastText,
        "&:hover, &:active": {
          backgroundColor: palette.primary.contrastText,
          color: palette.secondary.contrastText,
        },
      },
      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        opacity: 0.5,
      },
      "&.MuiButton-containedSizeLarge": {
        padding: spacing(1, 3.25),
      },
      "&.MuiButton-containedSizeSmall": {
        padding: spacing(0.5, 1.75),
      },
      "&.MuiButton-outlined": {
        border: "2px solid",
        boxShadow: "-9px -9px 16px #fff, 9px 9px 16px rgba(196, 196, 196, .5)",
        "&:hover, &:active": {
          boxShadow: "none",
        },
      },
      "&.MuiButton-outlinedSizeSmall": {
        boxShadow: "-4px -4px 8px #fff, 4px 4px 8px rgba(196, 196, 196, .5)",
        padding: spacing(0.25, 1.25),
      },
      "&.MuiButton-text": {
        minWidth: "auto",
        padding: 0,
        textDecoration: "underline",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      "&.button-cta": {
        margin: spacing(2, 0),
      },
    },
  })
);
