import React from "react";
import classNames from "classnames";
import { ListItem as MuiListItem } from "@material-ui/core";

import { useListItemStyles } from "./ListItem.styles";
import { listItemDefaultProps, listItemPropTypes } from "./config";

export const ListItem = ({ children, className, restListItemProps }) => {
  const styles = useListItemStyles();

  return (
    <MuiListItem
      {...restListItemProps}
      className={classNames(styles.listItem, className)}
    >
      {children}
    </MuiListItem>
  );
};

MuiListItem.defaultProps = listItemDefaultProps;
MuiListItem.propTypes = listItemPropTypes;
