import { useField } from "react-final-form";

import { subscription } from "./config";

export const useFieldState = (name) => {
  return useField(name, { subscription });
};

export const showError = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}) => {
  return !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );
};
