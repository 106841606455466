import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box, Typography } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby";

import { Link } from "components";

import { useBrandStyles } from "./Brand.styles";

export const Brand = ({
  brandNameProps,
  className,
  variant,
  ...restBrandProps
}) => {
  const styles = useBrandStyles();

  const stacked = (name) =>
    name.split(" ").map((split) => (
      <span className="stack" key={split}>
        {split}
      </span>
    ));

  return (
    <StaticQuery
      query={graphql`
        query BrandQuery {
          site {
            siteMetadata {
              acronym
              name
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { acronym, name },
        },
      }) => (
        <Box
          {...restBrandProps}
          className={classNames(`brand-${variant}`, styles.brand, className)}
        >
          <Typography
            {...brandNameProps}
            className={classNames(
              `brand-name-${variant}`,
              styles.brandName,
              brandNameProps.className
            )}
          >
            {variant === "short"
              ? acronym
              : variant === "stacked"
              ? stacked(name)
              : name}
          </Typography>
        </Box>
      )}
    />
  );
};

Brand.defaultProps = {
  brandNameProps: {
    className: null,
    component: "span",
    variant: "h5",
  },
  className: null,
  component: Link,
  to: "/",
  variant: "stacked",
};

Brand.propTypes = {
  brandNameProps: PropTypes.shape({
    className: PropTypes.string,
    component: PropTypes.elementType,
    variant: PropTypes.oneOf([
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "srOnly",
      "inherit",
    ]), // configure typography variants in theme.
  }), // typographyProps: https://material-ui.com/api/typography/#props
  className: PropTypes.string,
  component: PropTypes.elementType,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        acronym: PropTypes.string,
        name: PropTypes.string,
      }),
    }).isRequired,
  }),
  to: PropTypes.string,
  variant: PropTypes.oneOf(["long", "short", "stacked"]),
  // ...restBrandProps: https://material-ui.com/system/basics/#all-inclusive
};
