import { makeStyles } from "@material-ui/core/styles";

export const useHeaderCTAStyles = makeStyles(({ breakpoints, spacing }) => ({
  caption: {
    maxWidth: 800,
    [breakpoints.up("md")]: {
      maxWidth: 650,
      textAlign: "center",
    },
    [breakpoints.up("lg")]: {
      maxWidth: 850,
    },
  },
  card: {
    marginBottom: spacing(10),
  },
  cards: {
    listStyle: "none",
    padding: spacing(6, 0, 0),
    [breakpoints.up("md")]: {
      paddingTop: spacing(2),
    },
    [breakpoints.up("lg")]: {
      paddingTop: spacing(4),
    },
  },
  context: {
    margin: spacing(1, 0),
    [breakpoints.up("md")]: {
      margin: spacing(2, "auto"),
      textAlign: "center",
    },
  },
  heading: {
    margin: spacing(6, 0, 1),
    [breakpoints.up("sm")]: {
      marginTop: spacing(8),
    },
    [breakpoints.up("md")]: {
      margin: spacing(8, "auto", 2),
      textAlign: "center",
    },
  },
}));
