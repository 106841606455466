import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Divider as MuiDivider } from "@material-ui/core";

import { useDividerStyles } from "./Divider.styles";

export const Divider = ({ className, ...restDividerProps }) => {
  const styles = useDividerStyles();

  return (
    <MuiDivider
      {...restDividerProps}
      className={classNames(styles.divider, className)}
    />
  );
};

Divider.defaultProps = {
  absolute: false,
  className: null,
  component: "hr",
  flexItem: false,
  light: false,
  orientation: "horizontal",
  variant: "fullWidth",
};

Divider.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.elementType,
  flexItem: PropTypes.bool,
  light: PropTypes.bool,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  variant: PropTypes.oneOf(["fullWidth", "inset", "middle"]),
  // ...restDividerProps: https://material-ui.com/api/divider/#props
};
