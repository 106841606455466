import React from "react";
import { Container } from "@material-ui/core";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { components, mdxDefaultProps, mdxPropTypes } from "./config";

export const MDX = ({ body, children, ...restContainerProps }) => (
  <Container {...restContainerProps}>
    {body ? (
      <MDXRenderer>{body}</MDXRenderer>
    ) : (
      <MDXProvider components={components}>{children}</MDXProvider>
    )}
  </Container>
);

MDX.defaultProps = mdxDefaultProps;
MDX.propTypes = mdxPropTypes;
