import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Card,
  CardActions,
  Grid,
  IconButton,
  Input,
  Typography,
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";

import { useDropzoneInputStyles } from "./DropzoneInput.styles";

const messageActive = "Drop your file(s) here";
const messageDefault = "Drag and drop or click here to upload your file(s)";
const messageDisabled = "File attachments are disabled at this time";
const messageError = "File(s) exceed the max size limit";

export const DropzoneInput = ({
  accept,
  className,
  disabled,
  helperText,
  maxSize,
  multiple,
  onChange,
  files,
  setFiles,
}) => {
  const styles = useDropzoneInputStyles();
  const [message, setMessage] = useState(helperText);
  const { getInputProps, getRootProps, isDragActive, isDragReject } =
    useDropzone({
      accept,
      disabled,
      multiple,
      maxSize,
      onDragEnter: () => {
        if (isDragReject)
          setMessage(`${messageError}. Our current limit is ${maxSize} bytes.`);
        else setMessage(messageActive);
      },
      onDragLeave: () => {
        setMessage(messageDefault);
      },
      onDrop: (acceptedFiles) => {
        setFiles(acceptedFiles);
        setMessage(messageDefault);
      },
      onDropRejected: () => {
        setMessage(`${messageError}. Our current limit is ${maxSize} bytes.`);
      },
    });

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    setMessage(messageDefault);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.previewURL));
    },
    [files]
  );

  return (
    <Grid
      className={classNames(styles.container, className, {
        multiple: multiple,
      })}
      container
    >
      <Grid
        className={classNames(styles.dropzone, { disabled })}
        item
        {...getRootProps()}
      >
        <Input inputProps={{ ...getInputProps() }} />
        {!!helperText && (
          <Typography
            className={classNames(styles.message, {
              disabled,
              active: isDragActive,
              error: isDragReject,
            })}
            variant="body2"
          >
            {disabled ? messageDisabled : message}
          </Typography>
        )}
      </Grid>
      {files && files.length > 0 && (
        <Grid item>
          <Grid
            container
            direction="row"
            justifycontent="flex-start"
            alignItems="stretch"
            spacing={1}
          >
            {files.map((file, index) => (
              <Grid item xs={multiple ? 2 : 12} key={`${file.name}-${index}`}>
                <Card
                  className={classNames(styles.thumb, { multiple: multiple })}
                  variant="outlined"
                >
                  <img
                    alt={file.name}
                    className={styles.img}
                    src={URL.createObjectURL(file)}
                  />
                  <CardActions className={styles.thumbActions} disableSpacing>
                    <Typography variant="caption">{file.name}</Typography>
                    <IconButton
                      aria-label={`Remove ${file.name}`}
                      className={styles.thumbRemove}
                      onClick={removeFile(file)}
                      size="small"
                    >
                      <DeleteForever />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DropzoneInput.defaultProps = {
  accept: "image/*",
  className: undefined,
  disabled: false,
  helperText: messageDefault,
  maxSize: 3000000,
  onChange: undefined,
};

DropzoneInput.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
};
