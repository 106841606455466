import { makeStyles } from "@material-ui/core/styles";

export const useFormStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, typography }) => ({
    button: {
      width: "100%",
      [breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    buttons: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: spacing(4, 0),
      [breakpoints.up("lg")]: {
        marginBottom: spacing(8),
      },
    },
    buttonsItem: {
      "&:first-child": {
        marginTop: spacing(2),
        order: 1,
        [breakpoints.up("sm")]: {
          marginTop: 0,
          order: 0,
        },
      },
      "&:last-child": {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    debug: {
      padding: spacing(2),
    },
    heading: {
      color: palette.text.primary,
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1,
      margin: spacing(3, 0, 2),
      [breakpoints.up("lg")]: {
        marginTop: spacing(4),
      },
    },
    message: {
      color: palette.grey[700],
      margin: spacing(2, 0),
      "&.error": {
        color: palette.error.main,
      },
      "&.info": {
        color: palette.primary.dark,
      },
      "&.success": {
        color: palette.success.main,
      },
      "&.warning": {
        color: palette.primary.light,
      },
    },
    paper: {
      backgroundColor: palette.common.white,
      borderRadius: shape.borderRadiusSubtle,
      margin: spacing(0, -2),
      padding: spacing(2),
      [breakpoints.up("sm")]: {
        margin: spacing(0, -3),
        padding: spacing(3),
      },
      [breakpoints.up("md")]: {
        margin: spacing(0, -4),
        padding: spacing(4),
      },
      [breakpoints.up("lg")]: {
        margin: spacing(0, -5),
        padding: spacing(5),
      },
      "&.clear": {
        background: "transparent",
        boxShadow: "none",
        padding: 0,
      },
    },
    section: {
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
  })
);
