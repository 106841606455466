import React from "react";
import PropTypes from "prop-types";
import { FormSpy } from "react-final-form";

import { JobPost } from "compositions";
import { getDescription } from "utils/form";

// TODO:
// - render values.companyLogo in CompanyCard
//   - needs to work with blurb url (update FieldDropzone to save preview to form state)
// - use callback function to send data to airtable - post will be activated after payment is made

export const PreviewStep = ({ compImages, logoImages }) => (
  <FormSpy subscription={{ values: true }}>
    {({
      values: {
        companyDescription,
        companyImages,
        companyLogo,
        companyMission,
        companyName,
        companyUrl,
        hiringDescription,
        hiringManager,
        hiringStartDate,
        jobApplication,
        jobCategory,
        jobDescription,
        jobRegions,
        jobTitle,
        jobType,
      },
    }) => (
      <JobPost
        companyDescription={getDescription(companyDescription)}
        companyImages={compImages}
        companyLogo={logoImages}
        companyMission={companyMission}
        companyName={companyName}
        companyUrl={companyUrl}
        hiringDescription={getDescription(hiringDescription)}
        hiringManager={hiringManager}
        hiringStartDate={hiringStartDate}
        isPreview
        jobApplication={jobApplication}
        jobCategory={jobCategory}
        jobDescription={getDescription(jobDescription)}
        jobRegions={jobRegions}
        jobTitle={jobTitle}
        jobType={jobType}
      />
    )}
  </FormSpy>
);

PreviewStep.defaultProps = {
  buttons: {
    next: "Save and continue",
  },
};

PreviewStep.propTypes = {
  buttons: PropTypes.shape({
    next: PropTypes.string,
    prev: PropTypes.string,
    submit: PropTypes.string,
  }),
};
