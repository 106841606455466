import React from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";

import { CardPost, Section } from "components";

import { useSectionPostsStyles } from "./SectionPosts.styles";

export const SectionPosts = ({
  children,
  className,
  containerProps,
  filters,
  heading = "Blog",
  headingProps,
  postProps,
  posts,
  postsProps,
  ...rest
}) => {
  const styles = useSectionPostsStyles();
  const hasPosts = posts?.edges?.length > 0;

  return (
    <Section
      {...rest}
      containerProps={{
        maxWidth: "md",
        ...containerProps,
      }}
      className={classNames(styles.section, className, {
        "no-posts": !hasPosts,
      })}
    >
      {children}
      {hasPosts && (
        <Grid
          container
          spacing={2}
          {...postsProps}
          className={classNames(styles.posts, postsProps?.className)}
        >
          {posts.edges.map(({ node: { data, id } }) => {
            const path = `/blog/${data.id}`;
            const disqusConfig = {
              identifier: data.id,
              title: data.title,
              url: path,
            };

            return (
              <Grid
                item
                xs={12}
                key={id}
                {...postProps}
                className={classNames(styles.post)}
              >
                <CardPost path={path} disqusConfig={disqusConfig} {...data} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Section>
  );
};
