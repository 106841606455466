import { makeStyles } from "@material-ui/core/styles";

export const useSectionPostsStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    post: {
      zIndex: 1,
      "&:last-child": {
        borderBottom: 0,
      },
    },
    posts: {
      listStyle: "none",
      marginBottom: spacing(2),
      padding: 0,
      zIndex: 1,
    },
    section: {
      padding: 0,
      "&.no-posts": {
        marginBottom: spacing(4),
        marginTop: spacing(8),
        zIndex: 1,
      },
      "&.cta": {
        marginBottom: spacing(6),
        marginTop: 0,
        paddingTop: spacing(4),
        [breakpoints.up("sm")]: {
          marginBottom: spacing(18),
        },
      },
    },
  })
);
