import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Box,
  Button,
  ButtonGroup,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import { navigate } from "gatsby";

import { Link, Text } from "components";

import { useFilterStyles } from "./Filter.styles";

// TODO:
// - add sticky option to vertical filter

export const Filter = ({
  buttonClassName,
  buttonGroupProps,
  className,
  color,
  disableRipple,
  filters,
  heading,
  location,
  orientation,
  selectProps,
  variant,
  ...restFilterProps
}) => {
  const styles = useFilterStyles();
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"));
  const [filter, setFilter] = useState(location?.pathname);

  const handleChange = (event) => {
    setFilter(event.target.value);
    navigate(event.target.value);
  };

  return (
    <Box {...restFilterProps} className={classNames(styles.filter, className)}>
      {heading && (
        <Text className={styles.heading} variant="h4">
          {heading}
        </Text>
      )}
      {isMobile ? (
        <Select
          native
          value={filter}
          variant="outlined"
          onChange={handleChange}
          {...selectProps}
          className={classNames(styles.select, selectProps?.className)}
          inputProps={{
            name: "filter",
            id: "native-outlined-filter",
            ...selectProps?.inputProps,
          }}
        >
          {filters.map((button) => (
            <option key={button.id} value={button.path}>
              {button.label}
            </option>
          ))}
        </Select>
      ) : (
        <ButtonGroup
          aria-label={`${orientation} ${variant} ${color} button group`}
          color={color}
          disableRipple={disableRipple}
          orientation={orientation}
          variant={variant}
          {...buttonGroupProps}
          className={classNames(
            styles.buttons,
            buttonGroupProps?.className,
            variant && variant
          )}
        >
          {filters.map((button) => (
            <Button
              activeClassName="active"
              className={classNames(
                styles.button,
                button.className,
                orientation,
                buttonClassName
              )}
              component={Link}
              key={button.id}
              to={button.path}
            >
              {button.label}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </Box>
  );
};

Filter.defaultProps = {
  className: null,
  color: "inherit",
  component: "nav",
  heading: null,
  orientation: "horizontal",
  variant: "outlined",
};

Filter.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  component: PropTypes.elementType,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  heading: PropTypes.string,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  // ...restFilterProps: https://material-ui.com/system/basics/#all-inclusive
};
