import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Container,
  Stepper as MuiStepper,
  Step,
  StepLabel,
} from "@material-ui/core";

import { useStepperStyles } from "./Stepper.styles";

export const Stepper = ({
  activeStep,
  className,
  containerProps,
  steps,
  ...restStepperProps
}) => {
  const styles = useStepperStyles();

  if (!steps) return null;

  return (
    <Container {...containerProps}>
      <MuiStepper
        activeStep={activeStep}
        alternativeLabel
        className={classNames(styles.stepper, className)}
        {...restStepperProps}
      >
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel className={styles.label}>{step}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Container>
  );
};

Stepper.defaultProps = {
  activeStep: 0,
  className: null,
  containerProps: null,
  steps: undefined,
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  className: PropTypes.string,
  containerProps: PropTypes.shape({
    component: PropTypes.elementType,
    disableGutters: PropTypes.bool,
    fixed: PropTypes.bool,
    maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  }), // containerProps: https://material-ui.com/api/container/#props
  steps: PropTypes.arrayOf(PropTypes.string),
  // ...restStepperProps: https://material-ui.com/api/stepper/#props
};
