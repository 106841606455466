import { makeStyles } from "@material-ui/core/styles";

export const useSectionResourcesStyles = makeStyles(
  ({ breakpoints, spacing, typography }) => ({
    button: {
      backgroundColor: "transparent !important",
      border: "0 !important",
      fontWeight: typography.fontWeightRegular,
      justifyContent: "center",
      minWidth: 0,
      "&.active": {
        fontWeight: typography.fontWeightExtraBold,
      },
      [breakpoints.up("md")]: {
        justifyContent: "flex-start",
      },
    },
    filter: {
      paddingBottom: "0 !important",
      paddingTop: "0 !important",
      zIndex: 10,
    },
    main: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    resource: {
      zIndex: 1,
    },
    resources: {
      listStyle: "none",
      marginBottom: spacing(2),
      padding: 0,
    },
    link: {
      display: "inline-flex",
    },
    section: {},
  })
);
