import PropTypes from "prop-types";

import { sectionDefaultProps, sectionPropTypes } from "../default/config";

export const sectionJobsDefaultProps = {
  ...sectionDefaultProps,
  heading: null,
  headingProps: {
    component: "h3",
    variant: "h2",
  },
  jobs: null,
  jobsProps: {
    component: "ul",
    spacing: 2,
  },
  jobProps: {
    component: "li",
    md: 6,
    xs: 12,
  },
  link: null,
  linkProps: {
    color: "textPrimary",
    className: null,
    variant: "h4",
  },
  mascot: null,
  showSubscribeCard: true,
};

export const sectionJobsPropTypes = {
  ...sectionPropTypes,
  heading: PropTypes.string,
  headingProps: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
      className: PropTypes.string,
      color: PropTypes.oneOf([
        "initial",
        "inherit",
        "primary",
        "secondary",
        "textPrimary",
        "textSecondary",
        "error",
      ]), // color options are limited, use className for more styling options.
      component: PropTypes.elementType,
      variant: PropTypes.oneOf([
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "subtitle1",
        "subtitle2",
        "body1",
        "body2",
        "caption",
        "button",
        "overline",
        "srOnly",
        "inherit",
      ]), // configure typography variants in theme.
    }), // headingProps: https://material-ui.com/api/typography/#props
  ]),
  jobProps: PropTypes.shape({
    xs: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  jobs: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          data: PropTypes.shape({
            companyLogo: PropTypes.oneOfType([
              PropTypes.shape({
                localFiles: PropTypes.arrayOf(
                  PropTypes.shape({
                    childImageSharp: PropTypes.shape({
                      fluid: PropTypes.shape({
                        base64: PropTypes.string,
                        aspectRatio: PropTypes.number,
                        src: PropTypes.string,
                        srcSet: PropTypes.string,
                        sizes: PropTypes.string,
                      }),
                    }),
                    name: PropTypes.string,
                  })
                ),
              }),
              PropTypes.string,
            ]),
            companyName: PropTypes.string,
            date: PropTypes.string,
            id: PropTypes.string,
            jobCategory: PropTypes.oneOf([
              "Art",
              "Audio",
              "Design",
              "Development",
              "Social",
              "Video",
              "Writing",
            ]),
            jobTitle: PropTypes.string,
          }),
          id: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  jobsProps: PropTypes.shape({
    className: PropTypes.string,
    spacing: PropTypes.number,
  }),
  link: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  }),
  linkProps: PropTypes.shape({
    className: PropTypes.string,
    variant: PropTypes.oneOf([
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "srOnly",
      "inherit",
    ]),
  }),
  mascot: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    className: PropTypes.string,
    name: PropTypes.string,
  }),
  showSubscribeCard: PropTypes.bool,
};
