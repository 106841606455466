import { makeStyles } from "@material-ui/core/styles";

export const useFormFieldStyles = makeStyles(
  ({ palette, spacing, typography }) => ({
    field: {
      marginTop: spacing(1.5),
      "& .MuiInputLabel-outlined": {
        position: "relative",
        margin: spacing(0.5, 0, 1.5),
        transform: "none",
        ...typography.label,
        "&.Mui-focused": {
          color: palette.primary.dark,
        },
        "&.Mui-error": {
          color: palette.error.main,
        },
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: palette.background.default,
        borderRadius: "2px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.secondary.main,
          top: 0,
          "& legend": {
            display: "none",
          },
          "&:hover": {
            borderColor: palette.secondary.dark,
          },
        },
        "&.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.error.main,
          },
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary.dark,
          },
        },
      },
      "& .MuiFormHelperText-root": {
        color: palette.grey[700],
        lineHeight: 1.5,
        "&.Mui-error": {
          color: palette.error.main,
        },
        "&.MuiFormHelperText-contained": {
          margin: spacing(1, 0, 0.5),
        },
      },
    },
  })
);
