import PropTypes from "prop-types";

export const subscription = {
  dirtySinceLastSubmit: true,
  error: true,
  modified: true,
  submitError: true,
  touched: true,
  value: true,
};

/**
 * propTypes config
 */

export const defaultProps = {
  className: null,
  disabled: false,
  formControlProps: {
    fullWidth: true,
  },
  formHelperTextProps: null,
  helperText: null,
  inputLabelProps: {
    variant: "outlined",
  },
  label: null,
  required: false,
  variant: "outlined",
};

export const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  formControlProps: PropTypes.shape({
    fullWitdth: PropTypes.bool,
  }), // formControlProps: https://material-ui.com/api/form-control/#props
  formHelperTextProps: PropTypes.shape({
    className: PropTypes.string,
  }), // formHelperTextProps: https://material-ui.com/api/form-helper-text/#props
  helperText: PropTypes.string,
  inputLabelProps: PropTypes.shape({
    variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  }), // https://material-ui.com/api/input-label/#props
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};
