import { makeStyles } from "@material-ui/core/styles";

export const usePaperStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    paper: {
      backgroundColor: palette.background.default,
      boxShadow: `0px 1px 2px 0px ${palette.grey[200]}`,
      display: "flex",
      flexFlow: "row wrap",
      padding: spacing(4),
      [breakpoints.up("md")]: {
        padding: spacing(6),
      },
      [breakpoints.up("lg")]: {
        padding: spacing(8),
      },
      "&.clear-top": {
        marginTop: spacing(8),
      },
      "&.clear-bottom": {
        marginBottom: spacing(8),
      },
    },
  })
);
