import { makeStyles } from "@material-ui/core/styles";

export const useHeaderPostStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography, zIndex }) => ({
    caption: {
      margin: spacing(6, 0, 4),
      textAlign: "center",
    },
    category: {
      "&:hover": {
        color: palette.primary.dark,
      },
      "&.active": {
        cursor: "default",
        pointerEvents: "none",
        textDecoration: "none",
      },
    },
    commentCount: {
      marginLeft: 4,
    },
    container: {
      alignItems: "stretch",
      display: "flex",
      flexFlow: "column nowrap",
    },
    header: {
      padding: 0,
      zIndex: zIndex.appBar,
    },
    link: {
      textDecoration: "none !important",
      "&:hover": {
        color: palette.primary.dark,
      },
    },
    meta: {
      alignItems: "center",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "center",
      margin: spacing(0, "auto", 2),
      maxWidth: 800,
      width: "100%",
    },
    metaText: {
      alignItems: "center",
      display: "flex",
      flex: "none",
      justifyContent: "center",
      margin: spacing(0, 2),
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    title: {
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1.5,
      margin: spacing(1, 0, 0),
      [breakpoints.up("md")]: {
        marginTop: spacing(2),
      },
    },
  })
);
