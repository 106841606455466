import { makeStyles } from "@material-ui/core/styles";

export const useHeaderJobStyles = makeStyles(
  ({ breakpoints, palette, spacing, zIndex }) => ({
    caption: {
      margin: 0,
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    context: {
      margin: spacing(0, 0, 1),
      [breakpoints.up("md")]: {
        marginBottom: spacing(2),
      },
    },
    header: {
      backgroundColor: palette.background.default,
      borderBottom: `2px solid ${palette.divider}`,
      borderTop: `2px solid ${palette.divider}`,
      marginTop: "-2px",
      padding: spacing(6, 0),
      zIndex: zIndex.appBar,
      "&.preview": {
        marginTop: 0,
      },
    },
    heading: {
      margin: spacing(1, 0, 0),
      [breakpoints.up("md")]: {
        marginTop: spacing(2),
      },
    },
    logo: {
      marginRight: spacing(2),
      order: -1,
      width: 100,
      "& > img": {
        width: "100%",
      },
      [breakpoints.up("sm")]: {
        width: 150,
      },
    },
  })
);
