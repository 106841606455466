import { makeStyles } from "@material-ui/core/styles";

export const useAuthorStyles = makeStyles(({ spacing }) => ({
  author: {
    alignItems: "center",
    textDecoration: "none",
    "& > .gatsby-image-wrapper": {
      marginRight: spacing(1),
      overflow: "hidden",
      position: "relative",
      width: spacing(5),
    },
  },
}));
