import React from "react";
import classNames from "classnames";
// import { CommentCount } from "gatsby-plugin-disqus";
import { Box } from "@material-ui/core";
// import { ChatBubbleOutline } from "@material-ui/icons";

import { Header, Link, Text } from "components";
import { Author } from "containers";

import { useHeaderPostStyles } from "./HeaderPost.styles";
import { headerDefaultProps, headerPropTypes } from "../config";

export const HeaderPost = ({
  author,
  captionProps,
  category,
  children,
  className,
  containerProps,
  date,
  disqusConfig,
  id,
  metaProps,
  path,
  showComments,
  title,
  titleProps,
  ...restHeaderProps
}) => {
  const styles = useHeaderPostStyles();

  return (
    <Header
      {...restHeaderProps}
      captionProps={{
        ...captionProps,
        className: classNames(styles.caption, captionProps?.className),
      }}
      className={classNames(styles.header, className)}
      containerProps={{
        ...containerProps,
        className: classNames(styles.container, containerProps?.className),
      }}
      content={
        <>
          {category && (
            <Link
              activeClassName="active"
              className={styles.category}
              to={`/blog/${category.split(" ").join("-").toLowerCase()}`}
              variant="body1"
            >
              {category}
            </Link>
          )}
          {title && path ? (
            <Link className={styles.link} to={path}>
              <Text
                {...titleProps}
                className={classNames(styles.title, titleProps?.className)}
              >
                {title}
              </Text>
            </Link>
          ) : (
            <Text
              {...titleProps}
              className={classNames(styles.title, titleProps?.className)}
            >
              {title}
            </Text>
          )}
        </>
      }
    >
      {children}
      <Box
        {...metaProps}
        className={classNames(styles.meta, metaProps?.className)}
      >
        {author && <Author className={styles.metaText} name={author} />}
        {date && (
          <Text className={styles.metaText} variant="caption">
            {date}
          </Text>
        )}
        {/* {showComments && disqusConfig && (
          <Text className={styles.metaText} variant="caption">
            <ChatBubbleOutline />
            <CommentCount
              className={styles.commentCount}
              config={disqusConfig}
              placeholder="Comments"
            />
          </Text>
        )} */}
      </Box>
    </Header>
  );
};

HeaderPost.defaultProps = {
  ...headerDefaultProps,
  captionProps: {
    ...headerDefaultProps.captionProps,
  },
  alignContent: "center",
  alignItems: "center",
  containerProps: {
    ...headerDefaultProps.containerProps,
  },
  titleProps: {
    ...headerDefaultProps.headingProps,
    component: "h1",
    variant: "h2",
  },
  minHeight: 0,
};

HeaderPost.propTypes = {
  ...headerPropTypes,
};
