import { makeStyles } from "@material-ui/core/styles";

// TODO:
// - use custom gradient colors from palette for background image gradient overlays

export const useHeaderStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    button: {
      marginRight: spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttons: {
      display: "flex",
      margin: spacing(4, 0),
    },
    caption: {
      position: "relative",
      margin: spacing(0),
      [breakpoints.up("md")]: {
        margin: spacing(0, "auto"),
      },
      "&.narrow": {
        margin: 0,
        maxWidth: 500,
      },
      "&.space-right-image": {
        marginRight: spacing(8),
        [breakpoints.up("sm")]: {
          marginRight: spacing(15),
        },
        [breakpoints.up("md")]: {
          marginRight: spacing(19),
        },
        [breakpoints.up("lg")]: {
          marginRight: spacing(22),
        },
      },
    },
    container: {
      margin: "0 auto",
      zIndex: 1,
    },
    context: {
      fontWeight: typography.fontWeightRegular,
      margin: spacing(1, 0, 2),
    },
    header: {
      display: "flex",
      overflow: "hidden",
      position: "relative",
      "&.centered": {
        alignItems: "center",
        alignContent: "center",
      },
      "&.justified": {
        justifyContent: "center",
      },
      "&.primary": {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
      },
      "&.secondary": {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
      },
    },
    heading: {
      fontWeight: typography.fontWeightExtraBold,
      margin: spacing(6, "auto", 2),
      [breakpoints.up("md")]: {
        marginTop: spacing(8),
        "&.cta": {
          maxWidth: 750,
        },
      },
    },
    image: {
      height: "100%",
      position: "absolute !important",
      right: 0,
      top: 0,
      width: "100%",
      "&::after": {
        background:
          "linear-gradient(90deg, rgba(80, 80, 98, 1) 0%, rgba(80, 80, 98, 0.9) 100%)",
        content: "''",
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
      },
      "&.primary, .primary &": {
        "&::after": {
          background:
            "linear-gradient(90deg, rgba(50, 96, 206, 1) 0%, rgba(50, 96, 206, 0.9) 100%)",
        },
      },
      "&.secondary, .secondary &": {
        "&::after": {
          background:
            "linear-gradient(90deg, rgba(255, 213, 2, 1) 0%, rgba(255, 213, 2, 0.9) 100%)",
        },
      },
      "&.clear": {
        "&::after": {
          content: "none",
        },
      },
    },
  })
);
