import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { Grid, Typography } from "@material-ui/core";

import { CardJob, Filter, Link, Section, Text } from "components";
import { SubscribeCard } from "containers";

import { useSectionJobsStyles } from "./SectionJobs.styles";
import { sectionJobsDefaultProps, sectionJobsPropTypes } from "./config";

export const SectionJobs = ({
  children,
  className,
  filters,
  heading,
  headingProps,
  jobProps,
  jobs,
  jobsProps,
  link,
  linkProps,
  location,
  mascot,
  showSubscribeCard,
  ...restSectionProps
}) => {
  const styles = useSectionJobsStyles();
  const hasJobs = jobs?.edges?.length > 0;

  return (
    <Section
      {...restSectionProps}
      className={classNames(styles.section, className, { "no-jobs": !hasJobs })}
    >
      {heading && (
        <Text
          {...sectionJobsDefaultProps?.headingProps}
          {...headingProps}
          className={classNames(styles.heading, headingProps?.className)}
        >
          {heading}
        </Text>
      )}
      {hasJobs && mascot?.childImageSharp && (
        <Img
          alt={mascot.name || heading}
          className={classNames(styles.mascot, mascot.className)}
          {...mascot.childImageSharp}
        />
      )}
      {children}
      {hasJobs && filters && (
        <Filter
          className={styles.filter}
          filters={filters}
          location={location}
        />
      )}
      {hasJobs && (
        <>
          <Grid
            container
            {...sectionJobsDefaultProps?.jobsProps}
            {...jobsProps}
            className={classNames(styles.jobs, jobsProps?.className)}
          >
            {jobs.edges.map(({ node: { data, id } }) => (
              <Grid
                item
                key={id}
                {...sectionJobsDefaultProps?.jobProps}
                {...jobProps}
                className={classNames(styles.job)}
              >
                <CardJob
                  path={`/jobs/${data.jobCategory
                    .split(" ")
                    .join("-")
                    .toLowerCase()}/${data.id}`}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
          {showSubscribeCard && <SubscribeCard />}
        </>
      )}
      {hasJobs && link && (
        <>
          <Typography
            component={Link}
            to={link.path}
            {...sectionJobsDefaultProps?.linkProps}
            {...linkProps}
            className={classNames(styles.link, linkProps?.className)}
          >
            {link.label}
          </Typography>
        </>
      )}
    </Section>
  );
};

SectionJobs.defaultProps = sectionJobsDefaultProps;
SectionJobs.propTypes = sectionJobsPropTypes;
