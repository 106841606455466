import React from "react";
import classNames from "classnames";
import { Paper as MuiPaper } from "@material-ui/core";

import { usePaperStyles } from "./Paper.styles";
import { paperDefaultProps, paperPropTypes } from "./config";

export const Paper = ({ className, ...restPaperProps }) => {
  const styles = usePaperStyles();

  return (
    <MuiPaper
      {...restPaperProps}
      className={classNames(styles.paper, className)}
    />
  );
};

Paper.defaultProps = paperDefaultProps;
Paper.propTypes = paperPropTypes;
