import React, { useState } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import classNames from "classnames";
import { DatePicker } from "mui-rff";
import { format } from "date-fns";

import { useFormFieldStyles } from "../default/FormField.styles";
import { useFieldState } from "../utils";

// TODO:
// - move dateFormat and prop config to scoped config file
// - update styles for datepicker dialog - https://github.com/mui-org/material-ui-pickers/issues/393#issuecomment-427460325

const dateFormat = "MM/dd/yyyy";

export const FieldDate = ({ className, ...restFieldProps }) => {
  const styles = useFormFieldStyles();
  const {
    input: { value, onChange },
  } = useFieldState(restFieldProps.name);
  const defaultValue = value ? new Date(value) : null;
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(format(date, dateFormat));
  };

  return (
    <DatePicker
      {...restFieldProps}
      className={classNames(styles.field, className)}
      value={selectedDate}
      onChange={(date) => handleDateChange(date)}
    />
  );
};

FieldDate.defaultProps = {
  className: null,
  dateFunsUtils: DateFnsUtils,
  disablePast: true,
  disableToolbar: true,
  format: dateFormat,
  inputVariant: "outlined",
};

FieldDate.propTypes = {
  className: PropTypes.string,
  // ...restFieldProps: https://material-ui-pickers.dev/api/DatePicker
};
