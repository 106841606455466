import React from "react";
import PropTypes from "prop-types";
import { Container, Typography } from "@material-ui/core";

import { useFormStyles } from "components";

const context =
  "Every 30 days, your card will be charged $299 and your job post will be renewed. At every renewal, your job post will move back to the top of the category list. Your subscription can be cancelled anytime through the order receipt or by emailing us at support@remotecreativework.com.";

export const PayStep = ({ total }) => {
  const styles = useFormStyles();

  const heading = `Your total is ${total}`;

  return (
    <Container maxWidth="md">
      <Typography className={styles.heading} component="h4" variant="h3">
        {heading}
      </Typography>
      <Typography component="h6" variant="subtitle1">
        {context}
      </Typography>
    </Container>
  );
};

PayStep.defaultProps = {
  footerProps: {
    maxWidth: "md",
  },
  total: "$299",
};

PayStep.propTypes = {
  total: PropTypes.string,
};
