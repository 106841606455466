import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardContent } from "@material-ui/core";

import { HeaderPost, Text } from "components";

import { useCardPostStyles } from "./CardPost.styles";

export const CardPost = ({
  article,
  author,
  category,
  children,
  content,
  contentProps,
  className,
  date,
  disqusConfig,
  elevation,
  headerProps,
  showComments,
  title,
  path,
}) => {
  const styles = useCardPostStyles();

  const postHeaderProps = {
    author,
    category,
    date,
    disqusConfig,
    path,
    showComments,
    title,
    ...headerProps,
    captionProps: {
      ...headerProps?.captionProps,
      className: classNames(
        styles.caption,
        headerProps?.captionProps?.className
      ),
    },
    containerProps: {
      disableGutters: true,
      ...headerProps?.containerProps,
    },
    metaProps: {
      ...headerProps?.metaProps,
      className: classNames(styles.meta, headerProps?.metaProps?.className),
    },
    titleProps: {
      component: "h2",
      variant: "h2",
      ...headerProps?.titleProps,
    },
  };

  return (
    <Card className={classNames(styles.card, className)} elevation={elevation}>
      <HeaderPost {...postHeaderProps} />
      <CardContent
        {...contentProps}
        className={classNames(styles.content, contentProps.className)}
      >
        {content}
        {article?.childMdx?.excerpt && <Text>{article.childMdx.excerpt}</Text>}
      </CardContent>
      {children}
    </Card>
  );
};

CardPost.defaultProps = {
  className: null,
  contentProps: {
    className: null,
    component: "section",
  },
  elevation: 0,
  headerProps: {
    className: null,
    component: "header",
  },
};

CardPost.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  // ...restCardProps: https://material-ui.com/api/card/#props
};
