import PropTypes from "prop-types";

export const cardDefaultProps = {
  actionsProps: {
    className: null,
  },
  buttonProps: {
    color: "primary",
    size: "large",
    variant: "contained",
  },
  buttons: null,
  children: null,
  className: null,
  content: null,
  contentProps: {
    className: null,
    component: "section",
  },
  context: null,
  contextProps: {
    className: null,
    variant: "body1",
  },
  elevation: 0,
  heading: null,
  headingProps: {
    className: null,
    component: "h4",
    variant: "h3",
  },
  image: null,
  mediaProps: {
    className: null,
    component: "img",
  },
};

export const cardPropTypes = {
  actionsProps: PropTypes.shape({
    className: PropTypes.string,
  }), // cardActionsProps: https://material-ui.com/api/card-actions/
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
    size: PropTypes.oneOf(["large", "medium", "small"]),
    variant: PropTypes.oneOf(["contained", "outlined", "text"]),
    // buttonProps: https://material-ui.com/api/button/#props
  }),
  contentProps: PropTypes.shape({
    className: PropTypes.string,
  }), // cardContentProps: https://material-ui.com/api/card-content/
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  context: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    className: PropTypes.string,
  }), // image: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
  mediaProps: PropTypes.shape({
    className: PropTypes.string,
  }), // mediaProps: https://material-ui.com/api/card-media/#props
  // ...restCardProps: https://material-ui.com/api/card/#props
};
