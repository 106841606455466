import { makeStyles } from "@material-ui/core/styles";

export const useBlogPostStyles = makeStyles(({ spacing }) => ({
  card: {
    maxWidth: 480,
    minHeight: 0,
  },
  comments: {
    marginTop: spacing(6),
  },
  container: {
    marginBottom: spacing(8),
  },
  next: {
    margin: spacing(0, "auto"),
    maxWidth: 846,
  },
}));
