import PropTypes from "prop-types";

export const jobPostDefaultProps = {
  applyButtonLabel: "Apply",
  className: null,
  companyDescription: null,
  companyLogo: null,
  companyImages: null,
  companyMission: null,
  companyUrl: null,
  component: "div",
  hiringDescription: null,
  hiringManager: null,
  hiringStartDate: null,
  isPreview: false,
  websiteLinkLabel: "Visit website",
};

export const jobPostPropTypes = {
  applyButtonLabel: PropTypes.elementType,
  className: PropTypes.string,
  companyDescription: PropTypes.oneOfType([
    PropTypes.shape({
      childMdx: PropTypes.shape({
        body: PropTypes.string,
      }),
    }),
    PropTypes.string,
  ]),
  companyImages: PropTypes.oneOfType([
    PropTypes.shape({
      localFiles: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              base64: PropTypes.string,
              aspectRatio: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
        })
      ),
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  companyLogo: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        attachment: PropTypes.shape({}), // File attachment
        base64: PropTypes.string,
        previewURL: PropTypes.string,
      })
    ),
    PropTypes.shape({
      localFiles: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              base64: PropTypes.string,
              aspectRatio: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
        })
      ),
    }),
    PropTypes.string,
  ]),
  companyMission: PropTypes.oneOfType([
    PropTypes.shape({
      childMdx: PropTypes.shape({
        body: PropTypes.string,
      }),
    }),
    PropTypes.string,
  ]),
  companyName: PropTypes.string.isRequired,
  companyUrl: PropTypes.string,
  component: PropTypes.elementType,
  hiringDescription: PropTypes.oneOfType([
    PropTypes.shape({
      childMdx: PropTypes.shape({
        body: PropTypes.string,
      }),
    }),
    PropTypes.string,
  ]),
  hiringManager: PropTypes.string,
  hiringStartDate: PropTypes.string,
  isPreview: PropTypes.bool,
  jobApplication: PropTypes.string,
  jobDescription: PropTypes.oneOfType([
    PropTypes.shape({
      childMdx: PropTypes.shape({
        body: PropTypes.string,
      }),
    }),
    PropTypes.string,
  ]).isRequired,
  jobRegions: PropTypes.arrayOf(
    PropTypes.oneOf([
      "Africa",
      "Anywhere",
      "Asia",
      "Canada",
      "Europe",
      "US",
      "South America",
    ])
  ).isRequired,
  jobTitle: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  // ...restContainerProps: https://material-ui.com/api/container/#props
};
