import { makeStyles } from "@material-ui/core/styles";

export const useHeaderTitleStyles = makeStyles(({ spacing, typography }) => ({
  header: {
    padding: spacing(4, 0),
  },
  heading: {
    fontWeight: typography.fontWeightSemiBold,
    fontFamily: typography.fontHeading,
    margin: spacing(4, "auto", 0),
    textAlign: "center",
  },
}));
