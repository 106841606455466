import { makeStyles } from "@material-ui/core/styles";

export const useFieldQuillStyles = makeStyles(
  ({ palette, shape, spacing, transitions }) => ({
    quill: {
      background: palette.common.white,
      borderRadius: shape.borderRadius,
      border: "1px solid",
      borderColor: palette.secondary.main,
      display: "flex",
      flexFlow: "column nowrap",
      minHeight: 300,
      transition: transitions.create(["border-color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "& .ql-container": {
        border: 0,
        display: "flex",
        flex: 1,
        flexFlow: "column nowrap",
        "& .ql-editor": {
          flex: 1,
          padding: spacing(1, 1.75),
          paddingBottom: 18,
        },
      },
      "& .ql-toolbar": {
        alignContent: "center",
        alignItems: "center",
        border: 0,
        display: "flex",
        flex: "none",
        justifyContent: "space-between",
        padding: spacing(1, 1.65),
        paddingTop: 18,
        "& .ql-formats": {
          margin: 0,
          "& .ql-picker, & button": {
            border: "1px solid",
            borderColor: palette.grey[400],
            margin: spacing(0, 0.1),
          },
        },
        "&::after": {
          content: "none",
        },
      },
      "&:hover": {
        borderColor: palette.secondary.dark,
      },
      "&.active, &:active, &:focus": {
        borderColor: palette.primary.dark,
        borderWidth: 2,
      },
      "&.error": {
        borderColor: palette.error.main,
      },
    },
  })
);
