import React from "react";

import PropTypes from "prop-types";
import { TextField } from "mui-rff";
import { Container, Grid, Typography } from "@material-ui/core";

import {
  FieldDropzone,
  FieldQuill,
  useFormStyles,
  useFormFieldStyles,
} from "components";

import { fieldPropTypes } from "../../config";

const heading = "Tell us about your company";

export const CompanyStep = ({
  fields: {
    companyDescription,
    companyEmail,
    companyImages,
    companyLogo,
    companyMission,
    companyName,
    companyUrl,
  },
  logoImages,
  setLogoImages,
  compImages,
  setCompImages,
}) => {
  const styles = useFormStyles();
  const fieldStyles = useFormFieldStyles();

  return (
    <Container>
      <Typography className={styles.heading} component="h4" variant="h3">
        {heading}
      </Typography>
      <Grid
        className={styles.section}
        container
        component="section"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            {...companyName}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...companyUrl}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...companyEmail}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...companyMission}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FieldDropzone
            {...companyLogo}
            files={logoImages}
            setFiles={setLogoImages}
            multiple={false}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldDropzone
            {...companyImages}
            files={compImages}
            setFiles={setCompImages}
            multiple={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldQuill {...companyDescription} />
        </Grid>
      </Grid>
    </Container>
  );
};

CompanyStep.propTypes = {
  fields: PropTypes.shape({
    companyDescription: fieldPropTypes.companyDescription,
    companyImages: fieldPropTypes.companyImages,
    companyEmail: fieldPropTypes.companyEmail,
    companyLogo: fieldPropTypes.companyLogo,
    companyMission: fieldPropTypes.companyMission,
    companyName: fieldPropTypes.companyName,
    companyUrl: fieldPropTypes.companyUrl,
  }).isRequired,
};
