import React from "react";
import classNames from "classnames";
import { Typography } from "@material-ui/core";

import { useTextStyles } from "./Text.styles";
import { textDefaultProps, textPropTypes } from "./config";

export const Text = ({ children, className, ...restTextProps }) => {
  const styles = useTextStyles();

  return (
    <Typography
      {...restTextProps}
      className={classNames(styles.text, className)}
    >
      {children}
    </Typography>
  );
};

Text.defaultProps = textDefaultProps;
Text.propTypes = textPropTypes;
