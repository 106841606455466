import React from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";

import { Section } from "components";

import { useSectionSplitStyles } from "./SectionSplit.styles";
import { sectionSplitDefaultProps, sectionSplitPropTypes } from "./config";

// TODO:
// - add resizable column functionality (nice to have for later)

export const SectionSplit = ({
  children,
  className,
  gridProps,
  isFlipped,
  left,
  leftProps,
  right,
  rightProps,
  ...restSectionProps
}) => {
  const styles = useSectionSplitStyles();

  const renderLeft = () => {
    if (!left) return null;

    return (
      <Grid
        item
        {...sectionSplitDefaultProps.leftProps}
        {...leftProps}
        className={classNames(styles.gridLeft, leftProps.className)}
      >
        {left}
      </Grid>
    );
  };

  const renderRight = () => {
    if (!right) return null;

    return (
      <Grid
        item
        {...sectionSplitDefaultProps.rightProps}
        {...rightProps}
        className={classNames(styles.gridRight, rightProps.className)}
      >
        {right}
      </Grid>
    );
  };

  return (
    <Section
      {...restSectionProps}
      className={classNames(styles.section, className)}
    >
      <Grid
        container
        {...gridProps}
        className={classNames(styles.grid, gridProps.className)}
      >
        {isFlipped ? renderRight() : renderLeft()}
        {children}
        {isFlipped ? renderLeft() : renderRight()}
      </Grid>
    </Section>
  );
};

SectionSplit.defaultProps = sectionSplitDefaultProps;
SectionSplit.propTypes = sectionSplitPropTypes;
