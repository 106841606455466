import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardActionArea, CardContent } from "@material-ui/core";

import { Link, Text } from "components";

import { useCardNextStyles } from "./CardNext.styles";

export const CardNext = ({
  contentProps,
  children,
  content,
  className,
  elevation,
  nextLabel = "",
  path,
  title,
}) => {
  const styles = useCardNextStyles();

  return (
    <Card className={classNames(styles.card, className)} elevation={elevation}>
      <CardActionArea className={styles.link} component={Link} to={path}>
        <CardContent
          {...contentProps}
          className={classNames(styles.content, contentProps.className)}
        >
          {title && (
            <Text className={styles.title} variant="h4">
              {title}
            </Text>
          )}
          {content}
          {nextLabel && (
            <Text className={styles.nextLabel} component="span" variant="body1">
              {nextLabel}
            </Text>
          )}
        </CardContent>
      </CardActionArea>
      {children}
    </Card>
  );
};

CardNext.defaultProps = {
  className: null,
  contentProps: {
    className: null,
    component: "div",
  },
  elevation: 0,
};

CardNext.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  // ...restCardProps: https://material-ui.com/api/card/#props
};
