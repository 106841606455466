import {
  arrayOf,
  bool,
  elementType,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

// TODO:
// - define all available propTypes (reference provided api links)

export const headerDefaultProps = {
  alignContent: "flex-end",
  alignItems: "flex-end",
  bgcolor: null,
  buttonProps: {
    color: "primary",
    size: "large",
    variant: "contained",
  },
  buttons: null,
  buttonsProps: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  buttonsFooter: null,
  captionProps: {
    className: null,
    component: "figcaption",
  },
  children: null,
  className: null,
  color: "primary.contrastText",
  component: "header",
  containerProps: {
    component: "figure",
    disableGutters: false,
    fixed: false,
    maxWidth: "lg",
  },
  content: null,
  context: null,
  contextProps: {
    className: null,
    component: "h2",
    variant: "subtitle1",
  },
  heading: null,
  headingProps: {
    className: null,
    component: "h1",
    variant: "h2",
  },
  image: {
    alt: "header-background-image",
    childImageSharp: null,
    className: null,
  },
  justifyContent: "center",
  minHeight: "15vh",
};

export const headerPropTypes = {
  alignContent: string,
  alignItems: string,
  bgcolor: string,
  buttonProps: shape({
    className: string,
    component: elementType,
    id: string,
    label: string,
    path: string,
  }), // buttonProps: https://material-ui.com/api/button/#props
  buttons: arrayOf(
    shape({
      className: string,
      component: elementType,
      id: string,
      label: string,
      path: string,
    }) // buttonProps: https://material-ui.com/api/button/#props
  ),
  buttonsFooter: elementType,
  buttonsProps: shape({
    alignContent: string,
    alignItems: string,
    className: string,
    justifyContent: string,
  }), // buttonsProps: https://material-ui.com/system/basics/#all-inclusive
  captionProps: shape({
    className: string,
    component: elementType,
  }),
  children: node,
  className: string,
  color: string,
  component: elementType,
  containerProps: shape({
    className: string,
    component: elementType,
    disableGutters: bool,
    fixed: bool,
    maxWidth: oneOf(["xs", "sm", "md", "lg", "xl", false]),
  }), // containerProps: https://material-ui.com/api/container/#props
  content: oneOfType([elementType, func, node]),
  context: string,
  contextProps: shape({
    align: oneOf(["inherit", "left", "center", "right", "justify"]),
    className: string,
    color: oneOf([
      "initial",
      "inherit",
      "primary",
      "secondary",
      "textPrimary",
      "textSecondary",
      "error",
    ]), // color options are limited, use className for more styling options.
    component: elementType,
    variant: oneOf([
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "srOnly",
      "inherit",
    ]), // configure typography variants in theme.
  }), // contextProps: https://material-ui.com/api/typography/#props
  heading: string,
  headingProps: oneOfType([
    bool,
    shape({
      align: oneOf(["inherit", "left", "center", "right", "justify"]),
      className: string,
      color: oneOf([
        "initial",
        "inherit",
        "primary",
        "secondary",
        "textPrimary",
        "textSecondary",
        "error",
      ]), // color options are limited, use className for more styling options.
      component: elementType,
      variant: oneOf([
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "subtitle1",
        "subtitle2",
        "body1",
        "body2",
        "caption",
        "button",
        "overline",
        "srOnly",
        "inherit",
      ]), // configure typography variants in theme.
    }), // headingProps: https://material-ui.com/api/typography/#props
  ]),
  image: shape({
    childImageSharp: shape({
      fluid: shape({
        aspectRatio: number,
        base64: string,
        src: string,
        srcSet: string,
        sizes: string,
      }),
    }),
    className: string,
    name: string,
  }), // image: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
  justifyContent: string,
  minHeight: oneOfType([number, string]),
  // ...restHeaderProps: https://material-ui.com/system/basics/#all-inclusive
};
