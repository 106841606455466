import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";

import { Link } from "components";
import { isDateNew, renderAirtableImage } from "utils";

import { useCardJobStyles } from "./CardJob.styles";

export const CardJob = ({
  contentProps,
  children,
  content,
  className,
  companyLogo,
  companyName,
  companyLogoUrl,
  date,
  elevation,
  headerProps,
  jobTitle,
  path,
}) => {
  const styles = useCardJobStyles();
  const companyLogoImg =
    companyLogo &&
    companyLogo.hasOwnProperty("localFiles") &&
    companyLogo.localFiles[0].childImageSharp
      ? companyLogo
      : companyLogoUrl;
  return (
    <Card className={classNames(styles.card, className)} elevation={elevation}>
      <CardActionArea className={styles.link} component={Link} to={path}>
        <CardHeader
          action={
            isDateNew(date) && <Badge className={styles.badge}>NEW</Badge>
          }
          avatar={renderAirtableImage(
            companyLogoImg,
            companyName,
            styles.avatar
          )}
          {...headerProps}
          className={classNames(styles.header, headerProps.className)}
        />
        <CardContent
          {...contentProps}
          className={classNames(styles.content, contentProps.className)}
        >
          <Typography className={styles.context} component="h1" variant="h3">
            {jobTitle}
          </Typography>
          <Typography
            className={classNames(styles.context, "company")}
            component="h1"
            variant="h3"
          >
            {companyName}
          </Typography>
          {content}
        </CardContent>
      </CardActionArea>
      {children}
    </Card>
  );
};

CardJob.defaultProps = {
  className: null,
  contentProps: {
    className: null,
    component: "section",
  },
  elevation: 0,
  headerProps: {
    className: null,
    component: "header",
  },
};

CardJob.propTypes = {
  className: PropTypes.string,
  companyLogo: PropTypes.oneOfType([
    PropTypes.shape({
      localFiles: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              base64: PropTypes.string,
              aspectRatio: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
        }) // gatsby-image-props: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
      ),
    }),
    PropTypes.string,
  ]),
  companyName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  // ...restCardProps: https://material-ui.com/api/card/#props
};
