import { makeStyles } from "@material-ui/core/styles";

export const useLayoutDrawerStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions, typography }) => ({
    drawer: {
      backgroundColor: palette.primary.main,
      width: "100%",
    },
    header: {
      alignItems: "center",
      backgroundColor: palette.primary.main,
      borderBottom: "2px solid",
      borderColor: palette.primary.contrastText,
      display: "flex",
      justifyContent: "space-between",
      left: 0,
      padding: spacing(3, 1.5, 3, 2),
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 1,
      [breakpoints.up("sm")]: {
        paddingLeft: spacing(3),
      },
    },
    nav: {
      marginTop: spacing(12.5),
      padding: 0,
    },
    navItem: {
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightMedium,
      padding: spacing(4, 3),
      textDecoration: "none",
      transition: transitions.create(
        ["background-color", "border-color", "color"],
        {
          duration: transitions.duration.shortest,
          easing: transitions.easing.easeInOut,
        }
      ),
      "&:hover": {
        backgroundColor: palette.primary.contrastText,
        borderColor: palette.primary.contrastText,
        color: palette.secondary.contrastText,
      },
    },
  })
);
