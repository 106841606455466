import { makeStyles } from "@material-ui/core/styles";

export const useLinkStyles = makeStyles(
  ({ palette, transitions, typography }) => ({
    link: {
      fontWeight: typography.fontWeightMedium,
      transition: transitions.create(["color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&.button": {
        textDecoration: "none !important",
      },
      "&.MuiTypography-colorError, &.error": {
        color: palette.error.main,
        "&:hover": {
          color: palette.error.dark,
        },
      },
      "&.MuiTypography-colorPrimary, &.primary": {
        color: palette.primary.main,
        "&:hover": {
          color: palette.primary.dark,
        },
      },
      "&.MuiTypography-colorSecondary, &.secondary": {
        color: palette.secondary.main,
        "&:hover": {
          color: palette.secondary.dark,
        },
      },
      "&.MuiTypography-colorTextPrimary, &.text-primary": {
        color: palette.text.primary,
        "&:hover": {
          color: palette.text.secondary,
        },
      },
      "&.MuiTypography-colorTextSecondary, &.text-secondary": {
        color: palette.text.secondary,
        "&:hover": {
          color: palette.text.primary,
        },
      },
    },
  })
);
