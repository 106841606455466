import { bool, elementType, node, oneOf } from "prop-types";

export const listItemDefaultProps = {
  alignItems: "center",
  autoFocus: false,
  button: false,
  component: "li",
  dense: false,
  disabled: false,
  disableGutters: true,
  divider: false,
  selected: false,
};

export const listItemPropTypes = {
  alignItems: oneOf(["center", "flex-start"]),
  autoFocus: bool,
  button: bool,
  children: node.isRequired,
  component: elementType,
  dense: bool,
  disabled: bool,
  disableGutters: bool,
  divider: bool,
  selected: bool,
  // ...restListItemProps: https://material-ui.com/api/list-item/#props
};
