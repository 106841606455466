import { makeStyles } from "@material-ui/core/styles";

export const useCardStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, transitions, typography }) => ({
    card: {
      backgroundColor: palette.background.default,
      border: "2px solid",
      borderColor: palette.grey[600],
      borderRadius: shape.borderRadius,
      display: "flex",
      flexFlow: "column nowrap",
      minHeight: 350,
      "&.content": {
        borderColor: palette.secondary.main,
        minHeight: 250,
        "& .avatar": {
          borderRadius: "50%",
          overflow: "hidden",
          maxWidth: 100,
          "& > p": {
            margin: 0,
          },
          "& .gatsby-resp-image-wrapper > .gatsby-resp-image-image": {
            boxShadow: "none !important",
          },
        },
      },
      "&.narrow": {
        maxWidth: 500,
      },
      "&.space-bottom": {
        marginBottom: spacing(2),
        [breakpoints.up("sm")]: {
          marginBottom: spacing(3),
        },
        [breakpoints.up("xl")]: {
          marginBottom: spacing(4),
        },
        "&-lg": {
          marginBottom: spacing(6),
          [breakpoints.up("md")]: {
            marginBottom: spacing(10),
          },
          [breakpoints.up("xl")]: {
            marginBottom: spacing(16),
          },
        },
      },
      "&.space-top": {
        marginTop: spacing(2),
        [breakpoints.up("sm")]: {
          marginTop: spacing(3),
        },
        [breakpoints.up("xl")]: {
          marginTop: spacing(4),
        },
        "&-lg": {
          marginTop: spacing(4),
          [breakpoints.up("sm")]: {
            marginTop: spacing(6),
          },
          [breakpoints.up("xl")]: {
            marginTop: spacing(8),
          },
        },
      },
      "&.transparent": {
        backgroundColor: "transparent",
      },
      "&.wrapper": {
        border: 0,
        minHeight: "auto",
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
      justifyContent: "center",
      padding: spacing(3),
      "&:last-child": {
        paddingBottom: spacing(3),
      },
      [breakpoints.up("sm")]: {
        padding: spacing(4),
        "&:last-child": {
          paddingBottom: spacing(4),
        },
      },
      [breakpoints.up("md")]: {
        padding: spacing(6),
        "&:last-child": {
          paddingBottom: spacing(6),
        },
      },
      [breakpoints.up("lg")]: {
        padding: spacing(8),
        "&:last-child": {
          paddingBottom: spacing(8),
        },
      },
      ".content &": {
        padding: spacing(4, 2),
        "&:last-child": {
          paddingBottom: spacing(4),
        },
        [breakpoints.up("md")]: {
          padding: spacing(6, 3),
          "&:last-child": {
            paddingBottom: spacing(6),
          },
        },
      },
      ".cta &": {
        alignItems: "flex-start",
        padding: spacing(4, 3),
        [breakpoints.up("sm")]: {
          padding: spacing(4),
        },
        [breakpoints.up("md")]: {
          padding: spacing(5, 4),
        },
      },
      ".wrapper &": {
        padding: "0 !important",
      },
    },
    context: {
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightRegular,
      lineHeight: 1.5,
      margin: spacing(2, 0, 0),
    },
    header: {
      left: 0,
      padding: spacing(2, 3),
      position: "absolute",
      top: 0,
      width: "100%",
      [breakpoints.up("md")]: {
        padding: spacing(3),
      },
      [breakpoints.up("md")]: {
        padding: spacing(3, 6),
      },
      [breakpoints.up("lg")]: {
        padding: spacing(4, 8),
      },
      "& .MuiCardHeader-action": {
        margin: 0,
      },
      "& .MuiCardHeader-avatar": {
        display: "flex",
        margin: 0,
      },
    },
    heading: {
      lineHeight: 1.25,
      margin: 0,
      ".content &": {
        fontWeight: typography.fontWeightExtraBold,
        marginTop: spacing(2),
      },
    },
    image: {
      width: 50,
      [breakpoints.up("sm")]: {
        width: 75,
      },
    },
    link: {
      alignItems: "stretch",
      color: palette.text.primary,
      display: "flex",
      flexFlow: "column nowrap",
      transition: transitions.create(["color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&:hover": {
        color: palette.secondary.dark,
        textDecoration: "none",
      },
    },
    media: {},
  })
);
