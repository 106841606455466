import { bool, elementType, node, oneOf } from "prop-types";

export const textDefaultProps = {
  align: "inherit",
  color: "initial",
  component: "p",
  display: "initial",
  gutterBottom: false,
  noWrap: false,
  paragraph: false,
  variant: "body1",
};

export const textPropTypes = {
  align: oneOf(["inherit", "left", "center", "right", "justify"]),
  children: node.isRequired,
  color: oneOf([
    "initial",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "error",
  ]),
  component: elementType,
  display: oneOf(["initial", "block", "inline"]),
  gutterBottom: bool,
  noWrap: bool,
  paragraph: bool,
  variant: oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
    "srOnly",
    "inherit",
  ]),
  // ...restTextProps: https://material-ui.com/api/typography/#props
};
