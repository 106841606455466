import PropTypes from "prop-types";

export const sectionDefaultProps = {
  className: null,
  component: "section",
  containerProps: {
    className: null,
    component: "figure",
  },
};

export const sectionPropTypes = {
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  bgcolor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.elementType,
  containerProps: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      className: PropTypes.string,
      component: PropTypes.elementType,
      disableGutters: PropTypes.bool,
      fixed: PropTypes.bool,
      maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
    }), // containerProps: https://material-ui.com/api/container/#props
  ]),
  justifyContent: PropTypes.string,
  minHeight: PropTypes.string,
  // ...restSectionProps: https://material-ui.com/system/basics/#all-inclusive
};
