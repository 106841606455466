import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";

import { Button, Link } from "components";

import { useCardCTAStyles } from "./CardCTA.styles";

export const CardCTA = ({
  button,
  buttonProps,
  cardActionsProps,
  cardContentProps,
  children,
  className,
  content,
  context,
  heading,
  image,
  ...restCardProps
}) => {
  const styles = useCardCTAStyles();
  const showContent = content || context || heading;

  return (
    <Card {...restCardProps} className={classNames(styles.card, className)}>
      {image && image.childImageSharp && (
        <Img
          alt={image.name || heading}
          className={classNames(styles.image, image.className)}
          {...image.childImageSharp}
        />
      )}
      {showContent && (
        <CardContent
          {...cardContentProps}
          className={classNames(styles.content, cardContentProps.className)}
        >
          {heading && (
            <Typography className={styles.heading} variant="h3">
              {heading}
            </Typography>
          )}
          {context && <Typography variant="body1">{context}</Typography>}
          {content}
        </CardContent>
      )}
      {children}
      <CardActions
        {...cardActionsProps}
        className={classNames(styles.actions, cardActionsProps.className)}
      >
        <Button
          component={Link}
          {...buttonProps}
          {...button}
          className={classNames(
            styles.button,
            buttonProps?.className,
            button?.className
          )}
        >
          {button.label}
        </Button>
      </CardActions>
    </Card>
  );
};

CardCTA.defaultProps = {
  buttonProps: {
    color: "primary",
    size: "large",
    variant: "contained",
  },
  cardActionsProps: {
    className: null,
  },
  cardContentProps: {
    className: null,
  },
  children: null,
  className: null,
  content: null,
  context: null,
  elevation: 0,
  heading: null,
  image: null,
};

CardCTA.propTypes = {
  button: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.elementType.isRequired,
    target: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
    size: PropTypes.oneOf(["large", "medium", "small"]),
    variant: PropTypes.oneOf(["contained", "outlined", "text"]),
    // buttonProps: https://material-ui.com/api/button/#props
  }),
  cardActionsProps: PropTypes.shape({
    className: PropTypes.string,
  }), // cardActionsProps: https://material-ui.com/api/card-actions/
  cardContentProps: PropTypes.shape({
    className: PropTypes.string,
  }), // cardContentProps: https://material-ui.com/api/card-content/
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  context: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    className: PropTypes.string,
  }), // image: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
  // ...restCardProps: https://material-ui.com/api/card/#props
};
