import React from "react";
import PropTypes from "prop-types";
import { TextField } from "mui-rff";
import { Container, Grid, Typography } from "@material-ui/core";

import {
  FieldDate,
  FieldQuill,
  useFormStyles,
  useFormFieldStyles,
} from "components";

import { fieldPropTypes } from "../../config";

const heading = "Lastly, tell us about your hiring process";
const context =
  "Candidates love to be kept in the loop. Share details about how hiring works at your company";

export const HiringStep = ({
  fields: { hiringDescription, hiringManager, hiringStartDate },
}) => {
  const styles = useFormStyles();
  const fieldStyles = useFormFieldStyles();

  return (
    <Container>
      <Typography className={styles.heading} component="h4" variant="h3">
        {heading}
      </Typography>
      <Typography component="h6" variant="subtitle1">
        {context}
      </Typography>
      <Grid
        className={styles.section}
        container
        component="section"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            {...hiringManager}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldDate {...hiringStartDate} />
        </Grid>
        <Grid item xs={12}>
          <FieldQuill {...hiringDescription} />
        </Grid>
      </Grid>
    </Container>
  );
};

HiringStep.defaultProps = {
  buttons: {
    next: "Preview job post",
  },
};

HiringStep.propTypes = {
  buttons: PropTypes.shape({
    next: PropTypes.string,
    prev: PropTypes.string,
    submit: PropTypes.string,
  }),
  fields: PropTypes.shape({
    hiringDescription: fieldPropTypes.hiringDescription,
    hiringManger: fieldPropTypes.hiringManger,
    hiringStartDate: fieldPropTypes.hiringStartDate,
  }).isRequired,
};
