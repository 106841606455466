import { bool, elementType, node, shape, string } from "prop-types";

export const layoutDefaultProps = {
  children: null,
  footerProps: null,
  headerProps: null,
  mainProps: {
    bgcolor: "background.paper",
    className: null,
    component: "main",
  },
  seoProps: null,
};

export const layoutPropTypes = {
  children: node,
  data: shape({
    site: shape({
      siteMetadata: shape({
        copyright: string,
        memorial: string,
        updated: string,
        name: string,
        organization: shape({
          name: string,
          url: string,
        }),
        socialMedia: shape({
          instagram: string,
          twitter: string,
        }),
        stack: string,
        subscribe: string,
      }),
    }),
  }),
  footerProps: shape({
    bgcolor: string,
    className: string,
    color: string,
    isHidden: bool,
  }),
  headerProps: shape({
    button: shape({
      label: string,
      path: string,
    }),
    className: string,
    color: string,
    isHidden: bool,
    position: string,
  }),
  mainProps: shape({
    bgcolor: string,
    component: elementType,
  }), // mainProps: https://material-ui.com/system/basics/#all-inclusive
  seoProps: shape({
    description: string,
    title: string,
  }), // seoProps: https://github.com/ifiokjr/gatsby-plugin-next-seo#gatsbyseo-options
};
