import { makeStyles } from "@material-ui/core/styles";

export const useFilterStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    button: {
      backgroundColor: palette.background.default,
      border: "2px solid",
      borderColor: palette.primary.contrastText,
      color: palette.primary.contrastText,
      flex: 1,
      fontFamily: typography.fontBody,
      fontWeight: typography.fontWeightMedium,
      textDecoration: "none !important",
      textTransform: "initial",
      transition: "border-color 250ms ease-in-out",
      "&:hover": {
        backgroundColor: palette.background.paper,
        borderColor: "inherit",
        color: palette.primary.contrastText,
      },
      "&.active": {
        backgroundColor: palette.primary.main,
        borderColor: "inherit",
        color: palette.primary.contrastText,
        cursor: "default",
        pointerEvents: "none",
      },
      ".text &": {
        flex: "none",
        backgroundColor: "transparent",
      },
      [breakpoints.up("md")]: {
        flex: "none",
      },
    },
    buttons: {
      display: "flex",
      alignContent: "center",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      margin: spacing(0, -1),
      padding: spacing(4, 0, 2),
      "&.text": {
        margin: 0,
        paddingBottom: spacing(4),
        "&::before": {
          background:
            "linear-gradient(90deg, rgba(247,247,247,0) 0%, rgba(247,247,247,0.25) 15%, rgba(247,247,247,0.5) 30%, rgba(247,247,247,0.9) 50%, rgba(247,247,247,0.95) 75%, rgba(247,247,247,1) 100%)",
          content: "''",
          height: "100%",
          position: "absolute",
          right: 0,
          top: 0,
          width: 40,
          zIndex: 1,
          [breakpoints.up("md")]: {
            content: "none",
          },
        },
      },
      [breakpoints.up("md")]: {
        margin: 0,
      },
    },
    filter: {
      display: "flex",
      flex: 1,
      margin: spacing(2, 0),
      zIndex: 1,
      [breakpoints.up("md")]: {
        margin: 0,
      },
    },
    heading: {
      color: palette.text.secondary,
      fontWeight: typography.fontWeightSemiBold,
      margin: spacing(3, 0),
      marginBottom: 0,
    },
    select: {
      flex: 1,
    },
  })
);
