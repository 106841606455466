import React, { Children, useReducer } from "react";
import classNames from "classnames";
import { Box } from "@material-ui/core";
import { useSwipeable } from "react-swipeable";

import { useSwipeStyles } from "./Swipe.styles";

const NEXT = "next";
const PREV = "prev";
const initialState = { pos: 0, swiping: false, dir: null };

export const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

export const swipeReducer = (state, { type, numItems }) => {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        swiping: true,
        pos: state.pos === 0 ? numItems - 1 : state.pos - 1,
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        swiping: true,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1,
      };
    case "still":
      return { ...state, swiping: false };
    default:
      return state;
  }
};

export const Swipe = ({
  children,
  className,
  swipeClassName,
  swipeableClassName,
}) => {
  const styles = useSwipeStyles();
  const [state, dispatch] = useReducer(swipeReducer, initialState);
  const numItems = Children.count(children);

  const slide = (dir) => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "still" });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Box {...handlers} className={classNames(styles.wrapper, className)}>
      <Box
        className={classNames(
          styles.swipe,
          state.swiping ? "swiping" : "still",
          state.dir,
          swipeClassName
        )}
      >
        {Children.map(children, (child, index) => (
          <Box
            className={classNames(styles.swipeable, swipeableClassName)}
            key={index}
            order={getOrder({ index: index, pos: state.pos, numItems })}
          >
            {child}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
