import React from "react";
import PropTypes from "prop-types";
import { Select, TextField } from "mui-rff";
import { Container, Grid, Typography } from "@material-ui/core";

import { FieldQuill, useFormStyles, useFormFieldStyles } from "components";

import { fieldPropTypes } from "../../config";

const heading = "Tell us about this job";

export const JobStep = ({
  fields: {
    jobApplication,
    jobCategory,
    jobDescription,
    jobRegions,
    jobTitle,
    jobType,
  },
}) => {
  const styles = useFormStyles();
  const fieldStyles = useFormFieldStyles();

  return (
    <Container>
      <Typography className={styles.heading} component="h4" variant="h3">
        {heading}
      </Typography>
      <Grid
        className={styles.section}
        container
        component="section"
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <TextField
            {...jobTitle}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            {...jobType}
            formControlProps={{ className: fieldStyles.field }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            {...jobCategory}
            formControlProps={{ className: fieldStyles.field }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            {...jobRegions}
            formControlProps={{ className: fieldStyles.field }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FieldQuill {...jobDescription} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...jobApplication}
            className={fieldStyles.field}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

JobStep.propTypes = {
  fields: PropTypes.shape({
    jobApplication: fieldPropTypes.jobApplication,
    jobCategory: fieldPropTypes.jobCategory,
    jobDescription: fieldPropTypes.jobDescription,
    jobRegions: fieldPropTypes.jobRegions,
    jobTitle: fieldPropTypes.jobTitle,
    jobType: fieldPropTypes.jobType,
  }).isRequired,
};
