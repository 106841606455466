import { makeStyles } from "@material-ui/core/styles";

export const useLayoutFooterStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions, typography }) => ({
    footer: {
      borderTop: "2px solid",
      borderColor: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      padding: spacing(6, 0, 4),
      zIndex: 1,
      [breakpoints.up("md")]: {
        paddingTop: spacing(8),
      },
    },
    nav: {
      alignItems: "flex-start",
      margin: spacing(6, 0),
      [breakpoints.up("md")]: {
        marginBottom: spacing(12),
        marginTop: spacing(12),
      },
    },
    navList: {
      padding: 0,
      "&.social-media": {
        padding: spacing(6, 0),
        [breakpoints.up("md")]: {
          padding: 0,
        },
      },
    },
    navItem: {
      padding: 0,
      ".social-media &": {
        display: "inline-flex",
        marginRight: spacing(2),
        width: "auto",
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    link: {
      color: palette.text.secondary,
      display: "flex",
      fontWeight: typography.fontWeightRegular,
      textDecoration: "none",
      transition: transitions.create(["color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      "&:hover": {
        color: palette.primary.contrastText,
        textDecoration: "underline",
      },
      "&.inline": {
        display: "inline-flex",
        lineHeight: "inherit",
        textDecoration: "underline",
      },
    },
    text: {
      color: palette.text.secondary,
      display: "block",
      margin: spacing(0.5, 0),
    },
  })
);
