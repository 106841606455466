import { makeStyles } from "@material-ui/core/styles";

export const useSubscribeCardStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    card: {
      backgroundColor: "#eee !important",
      marginBottom: spacing(4),
      minHeight: "250px !important",
      order: 1,
      overflow: "initial",
      position: "relative",
      [breakpoints.up("md")]: {
        order: 0,
        overflow: "hidden",
      },
    },
    cardButton: {
      marginTop: spacing(4),
    },
    cardContent: {
      paddingRight: "85px !important",
      [breakpoints.up("md")]: {
        paddingRight: "165px !important",
      },
    },
    cardText: {
      margin: 0,
      marginTop: spacing(2),
    },
    heading: {
      color: palette.text.primary,
    },
    mascot: {
      bottom: -185,
      position: "absolute !important",
      right: -335,
      transform: "scaleX(-1) rotate(25deg)",
      width: 585,
      [breakpoints.up("md")]: {
        bottom: spacing(-30),
        right: spacing(-24),
        transform: "scaleX(-1)",
      },
    },
  })
);
