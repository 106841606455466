import { makeStyles } from "@material-ui/core/styles";

export const useCardJobStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, transitions, typography }) => ({
    avatar: {
      borderRadius: "50%",
      overflow: "hidden",
      width: "100%",
    },
    badge: {
      border: "2px solid",
      borderColor: palette.primary.light,
      borderRadius: shape.borderRadius,
      color: palette.primary.light,
      fontWeight: typography.fontWeightMedium,
      padding: spacing(0.25, 0.75),
      textTransform: "uppercase",
      transition: transitions.create(["border-color", "color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      [breakpoints.up("sm")]: {
        padding: spacing(0.5, 1),
      },
      "a:hover &": {
        borderColor: palette.secondary.dark,
        color: palette.secondary.dark,
      },
    },
    card: {
      backgroundColor: palette.background.default,
      border: "2px solid",
      borderColor: palette.text.secondary,
      borderRadius: shape.borderRadius,
      display: "flex",
      minHeight: 250,
      transition: transitions.create(["background-color", "border-color"], {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeInOut,
      }),
      [breakpoints.up("md")]: {
        minHeight: 320,
      },
      "&:hover": {
        backgroundColor: palette.primary.main,
        borderColor: palette.secondary.dark,
        textDecoration: "none",
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
      justifyContent: "center",
      padding: spacing(12, 4),
    },
    context: {
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightExtraBold,
      lineHeight: 1.25,
      "&.company": {
        fontWeight: typography.fontWeightRegular,
        color: palette.text.secondary,
      },
      "&:hover": {
        color: palette.text.secondary,
      },
    },
    header: {
      alignItems: "flex-start",
      left: 0,
      minHeight: spacing(12),
      padding: spacing(4, 4, 1),
      position: "absolute",
      top: 0,
      width: "100%",
      "& .MuiCardHeader-action": {
        marginRight: spacing(-2),
        marginTop: spacing(-2),
      },
      "& .MuiCardHeader-avatar": {
        display: "flex",
        height: 50,
        margin: 0,
        width: 50,
      },
    },
    link: {
      alignItems: "stretch",
      color: palette.text.primary,
      display: "flex",
      flexFlow: "column nowrap",
      textDecoration: "none !important",
      "&:hover": {
        color: palette.secondary.dark,
      },
    },
  })
);
