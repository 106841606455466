import { useStaticQuery, graphql } from "gatsby";

export const useStripePriceQuery = () => {
  const { price } = useStaticQuery(graphql`
    query StripePriceQuery {
      price: stripePrice(active: { eq: true }) {
        unit_amount_decimal
        currency
        id
      }
    }
  `);

  return price;
};
