import { bool, elementType, node } from "prop-types";

export const listDefaultProps = {
  component: "ul",
  dense: false,
  disablePadding: true,
  subheader: null,
};

export const listPropTypes = {
  children: node.isRequired,
  component: elementType,
  dense: bool,
  disablePadding: bool,
  subheader: node,
  // ...restListProps: https://material-ui.com/api/list/#props
};
