import React from "react";
import { FormHelperText } from "@material-ui/core";

export const FieldHelperText = ({
  helperText,
  showError,
  meta,
  ...restMessageProps
}) => {
  if (!helperText) return null;

  if (showError) {
    return (
      <FormHelperText {...restMessageProps}>
        {meta.error || meta.submitError}
      </FormHelperText>
    );
  }

  return <FormHelperText {...restMessageProps}>{helperText}</FormHelperText>;
};
